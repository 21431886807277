import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: 'new 0 0 256 256',
      }}
      viewBox="0 0 256 256"
      {...props}
    >
      <style>
        {
          '.st2{fill:none;stroke:#6b1d1d;stroke-width:.5;stroke-miterlimit:10}.st3{fill:#3a312a}.st4{fill:#87796f}.st12,.st7{fill:#fce8cb}.st12{fill-rule:evenodd;clip-rule:evenodd}.st13{fill:#f5978a}.st14{fill:#d7e057}.st22{fill:#ef6d7a}'
        }
      </style>
      <g id="Layer_1">
        <path
          d="M140.648 16.314H33.605c-9.447 0-17.105 7.658-17.105 17.106v130.289c0 9.447 7.658 17.105 17.105 17.105h188.789c9.447 0 17.105-7.658 17.105-17.105V33.419c0-9.447-7.658-17.106-17.105-17.106H140.648z"
          className="st14"
        />
        <path
          d="M222.395 182.314H33.605c-10.259 0-18.605-8.346-18.605-18.606V33.419c0-10.259 8.347-18.606 18.605-18.606h107.043a1.5 1.5 0 1 1 0 3H33.605C25.001 17.813 18 24.815 18 33.419v130.289c0 8.605 7.001 15.606 15.605 15.606h188.789c8.604 0 15.605-7.001 15.605-15.606V33.419c0-8.605-7.001-15.606-15.605-15.606h-69.042a1.5 1.5 0 1 1 0-3h69.042c10.259 0 18.605 8.347 18.605 18.606v130.289c.001 10.259-8.346 18.606-18.604 18.606z"
          className="st3"
        />
        <path
          d="M101.584 89.883a7.832 7.832 0 1 1-15.664 0 7.832 7.832 0 0 1 15.664 0z"
          className="st12"
        />
        <path
          d="M93.752 99.215c-5.146 0-9.332-4.187-9.332-9.332s4.187-9.332 9.332-9.332c5.145 0 9.332 4.187 9.332 9.332s-4.187 9.332-9.332 9.332zm0-15.664c-3.491 0-6.332 2.84-6.332 6.332s2.841 6.332 6.332 6.332c3.491 0 6.332-2.84 6.332-6.332s-2.841-6.332-6.332-6.332z"
          className="st3"
        />
        <path
          d="M162.651 86.081c0 19.175-18.467 48.608-34.72 48.608-15.514 0-34.72-29.433-34.72-48.608s15.545-34.72 34.72-34.72c19.176 0 34.72 15.545 34.72 34.72z"
          className="st7"
        />
        <path
          d="M127.932 136.189c-7.715 0-16.612-6.473-24.411-17.76-7.284-10.54-11.809-22.935-11.809-32.347 0-19.972 16.248-36.22 36.22-36.22 19.972 0 36.22 16.248 36.22 36.22-.001 20.239-19.08 50.107-36.22 50.107zm0-83.328c-18.317 0-33.22 14.902-33.22 33.22 0 17.9 18.559 47.107 33.22 47.107 6.887 0 15.191-6.152 22.215-16.458 6.788-9.96 11.005-21.704 11.005-30.65-.001-18.316-14.903-33.219-33.22-33.219z"
          className="st3"
        />
        <path
          d="M119.062 85.689a3.389 3.389 0 1 1-6.777 0 3.389 3.389 0 0 1 6.777 0zM143.578 85.689a3.389 3.389 0 1 1-6.777 0 3.389 3.389 0 0 1 6.777 0z"
          className="st3"
        />
        <path
          d="M114.738 95.764c0 2.41-2.614 4.364-5.838 4.364s-5.838-1.954-5.838-4.364 2.614-4.364 5.838-4.364 5.838 1.954 5.838 4.364zM152.8 95.764c0 2.41-2.614 4.364-5.838 4.364s-5.838-1.954-5.838-4.364 2.614-4.364 5.838-4.364 5.838 1.954 5.838 4.364z"
          className="st13"
        />
        <path
          d="M128.332 43.698c-19.847 0-35.936 15.382-35.936 34.358 0 2.008.363 3.504 1.038 5.374.318.882 5.464-1.933 5.902-2.242 2.191-1.543 3.982-3.633 5.32-5.945a20.238 20.238 0 0 0 1.386-2.918c1.184-3.121 4.403-11.806 7.671-11.124 4.382.914 9.349 1.43 14.618 1.43s10.236-.516 14.619-1.43c3.268-.681 6.487 8.003 7.671 11.124a20.34 20.34 0 0 0 1.386 2.918c2.211 3.824 5.945 7.464 10.487 8.127.011-.219.288-.294.495-.369 1.487-.54 1.278-3.753 1.278-4.945.001-18.975-16.088-34.358-35.935-34.358z"
          className="st4"
        />
        <path
          d="M93.726 85.083c-.213 0-.406-.024-.576-.078a1.738 1.738 0 0 1-1.126-1.065c-.676-1.871-1.127-3.588-1.128-5.883 0-19.772 16.794-35.858 37.437-35.858 20.643 0 37.437 16.085 37.437 35.858l.004.38c.026 1.761.073 5.014-2.156 5.931a1.496 1.496 0 0 1-1.338.488c-4.408-.643-8.732-3.955-11.569-8.86a21.781 21.781 0 0 1-1.489-3.137l-.075-.197c-3.753-9.901-5.566-10.06-5.887-9.99-4.652.97-9.674 1.461-14.926 1.461-5.252 0-10.273-.492-14.925-1.461-.317-.07-2.137.088-5.89 9.997l-.072.191a21.781 21.781 0 0 1-1.489 3.135c-1.515 2.618-3.505 4.837-5.756 6.423-.045.027-4.267 2.665-6.476 2.665zm34.606-39.885c-18.988 0-34.437 14.74-34.437 32.858.001 1.478.218 2.617.605 3.848 1.257-.463 3.462-1.605 3.986-1.951 1.885-1.328 3.574-3.219 4.872-5.461.5-.868.932-1.777 1.282-2.699l.071-.189c2.52-6.65 5.387-12.693 9.309-11.871 4.449.928 9.265 1.398 14.312 1.398 5.047 0 9.862-.47 14.313-1.398 3.909-.815 6.786 5.219 9.305 11.864l.074.196c.349.92.78 1.829 1.283 2.702 2.232 3.86 5.464 6.532 8.752 7.27a2.97 2.97 0 0 1 .357-.152c.185-.237.388-.981.355-3.132l-.004-.425c.002-18.118-15.447-32.858-34.435-32.858z"
          className="st3"
        />
        <path
          d="M98.561 50.441c5.893 7.421 12.944 15.333 21.458 17.754 13.714 3.899 37.989-.965 37.989-20.705 0-12.623-10.58-27.879-30.325-16.263-24.475 14.398-38.681-13.28-38.681-.657 0 3.99 1.607 7.969 3.717 11.59l5.842 8.281z"
          className="st4"
        />
        <path
          d="M129.615 70.93c-3.623 0-7.071-.458-10.006-1.292-8.762-2.491-15.859-10.251-22.224-18.264-.018-.022-.034-.045-.051-.068l-5.841-8.281a1.433 1.433 0 0 1-.07-.109c-2.639-4.527-3.922-8.566-3.922-12.345 0-1.665.194-3.687 1.71-4.545 1.793-1.016 3.879.235 7.038 2.13 6.239 3.743 16.685 10.008 30.672 1.78 10.616-6.247 17.989-4.616 22.305-2.148 7.103 4.062 10.281 12.823 10.281 19.704 0 6.978-2.917 12.87-8.436 17.039-5.954 4.495-14.068 6.399-21.456 6.399zM99.762 49.542c6.046 7.609 12.733 14.955 20.668 17.21 8.202 2.331 20.74 1.495 28.834-4.617 4.807-3.631 7.244-8.558 7.244-14.645 0-6.014-2.712-13.634-8.77-17.099-5.2-2.974-11.871-2.238-19.294 2.129-15.523 9.129-27.371 2.025-33.736-1.793-1.472-.882-3.279-1.967-3.986-2.052-.08.198-.219.713-.219 1.894 0 3.219 1.139 6.746 3.481 10.779l5.778 8.194zM127.931 115.744c-5.108 0-8.792-2.297-10.948-6.827a1.5 1.5 0 0 1 2.709-1.289c1.661 3.49 4.279 5.116 8.239 5.116 4.996 0 7.541-3.27 8.189-4.998a1.5 1.5 0 0 1 2.809 1.053c-1.253 3.345-5.23 6.945-10.998 6.945zM126.57 134.635c-20.814 0-37.748-16.934-37.748-37.748a1.5 1.5 0 1 1 3 0c0 19.16 15.588 34.748 34.748 34.748a1.5 1.5 0 1 1 0 3z"
          className="st3"
        />
        <path
          d="M132.117 137.178h-7.503a3.712 3.712 0 0 1-3.712-3.712v-2.123a3.711 3.711 0 0 1 3.712-3.711h7.503a3.711 3.711 0 0 1 3.711 3.711v2.123a3.71 3.71 0 0 1-3.711 3.712z"
          className="st22"
        />
        <path
          d="M132.117 138.678h-7.503a5.217 5.217 0 0 1-5.211-5.211v-2.123a5.217 5.217 0 0 1 5.211-5.211h7.503a5.218 5.218 0 0 1 5.212 5.211v2.123a5.218 5.218 0 0 1-5.212 5.211zm-7.503-9.545a2.214 2.214 0 0 0-2.211 2.211v2.123c0 1.219.992 2.211 2.211 2.211h7.503c1.22 0 2.212-.992 2.212-2.211v-2.123a2.214 2.214 0 0 0-2.212-2.211h-7.503z"
          className="st3"
        />
        <path
          d="M80.278 93.3v1.623a7.574 7.574 0 0 0 7.575 7.575h4.124a2.104 2.104 0 0 0 2.104-2.104V78.678a2.104 2.104 0 0 0-2.104-2.104h-4.124a7.575 7.575 0 0 0-7.575 7.575V93.3z"
          className="st22"
        />
        <path
          d="M91.977 103.998h-4.124c-5.004 0-9.075-4.071-9.075-9.075V84.149c0-5.004 4.071-9.075 9.075-9.075h4.124a3.608 3.608 0 0 1 3.604 3.604v21.715a3.608 3.608 0 0 1-3.604 3.605zm-4.124-25.924a6.082 6.082 0 0 0-6.075 6.075v10.773a6.082 6.082 0 0 0 6.075 6.075h4.124a.605.605 0 0 0 .604-.604V78.678a.605.605 0 0 0-.604-.604h-4.124z"
          className="st3"
        />
        <path
          d="M164.338 102.498h2.675a8.71 8.71 0 0 0 8.71-8.71v-8.505a8.71 8.71 0 0 0-8.71-8.71h-2.675a2.419 2.419 0 0 0-2.419 2.419v21.085a2.42 2.42 0 0 0 2.419 2.421z"
          className="st22"
        />
        <path
          d="M167.013 103.998h-2.675a3.923 3.923 0 0 1-3.919-3.919V78.993a3.923 3.923 0 0 1 3.919-3.919h2.675c5.629 0 10.209 4.58 10.209 10.209v8.504c0 5.631-4.58 10.211-10.209 10.211zm-2.675-25.924a.92.92 0 0 0-.919.919v21.085a.92.92 0 0 0 .919.919h2.675c3.975 0 7.209-3.234 7.209-7.209v-8.504c0-3.975-3.234-7.209-7.209-7.209h-2.675zM99.948 241.186a1.5 1.5 0 0 1-1.5-1.5v-10.878a1.5 1.5 0 1 1 3 0v10.878a1.5 1.5 0 0 1-1.5 1.5z"
          className="st3"
        />
        <path
          d="M55.646 239.685h27.969v-13.709c0-8.355-6.773-15.128-15.128-15.128h-9.995v-6.496c-2.765 1.917-5.625 3.047-8.401 3.047-2.663 0-5.472-1.142-8.226-3.081h-.17v6.53h-10c-8.356 0-15.128 6.773-15.128 15.128v13.709h39.079z"
          className="st7"
        />
        <path
          d="M83.615 241.185H55.646a1.5 1.5 0 1 1 0-3h26.469v-12.21c0-7.515-6.114-13.628-13.629-13.628h-9.995a1.5 1.5 0 0 1-1.5-1.5v-3.824c-2.357 1.246-4.67 1.875-6.9 1.875-2.201 0-4.513-.663-6.895-1.977v3.925a1.5 1.5 0 0 1-1.5 1.5H31.694c-7.515 0-13.628 6.113-13.628 13.628v12.21h24.787a1.5 1.5 0 1 1 0 3H16.566a1.5 1.5 0 0 1-1.5-1.5v-13.71c0-9.169 7.459-16.628 16.628-16.628h8.501v-5.03a1.5 1.5 0 0 1 1.5-1.5h.17c.31 0 .611.096.864.274 2.645 1.863 5.121 2.808 7.361 2.808 2.347 0 4.886-.936 7.546-2.78a1.501 1.501 0 0 1 2.355 1.232v4.996h8.495c9.169 0 16.629 7.459 16.629 16.628v13.71a1.5 1.5 0 0 1-1.5 1.501z"
          className="st3"
        />
        <path
          d="M55.646 239.686h27.969v-13.715c0-8.355-6.773-15.128-15.128-15.128h-2.391l.062.045-10.396 13.788-5.365-4.042-5.365 4.042-10.396-13.788.062-.045h-3.002c-8.356 0-15.128 6.773-15.128 15.128v13.715h39.078z"
          style={{
            fill: '#8ac6dd',
          }}
        />
        <path
          d="M83.615 241.186H55.646a1.5 1.5 0 1 1 0-3h26.469v-12.215c0-7.515-6.113-13.628-13.628-13.628H66.94l-9.981 13.237a1.498 1.498 0 0 1-2.1.295l-4.463-3.362-4.463 3.362a1.5 1.5 0 0 1-2.1-.295l-9.982-13.237h-2.157c-7.515 0-13.629 6.114-13.629 13.628v12.215h24.787a1.5 1.5 0 1 1 0 3H16.566a1.5 1.5 0 0 1-1.5-1.5v-13.715c0-9.169 7.46-16.628 16.629-16.628h3.002c.646 0 1.217.412 1.422 1.023l9.207 12.211 4.168-3.14a1.502 1.502 0 0 1 1.805 0l4.168 3.14 9.288-12.318c.233-.542.742-.915 1.341-.915h2.392c9.169 0 16.628 7.459 16.628 16.628v13.715a1.501 1.501 0 0 1-1.501 1.499z"
          className="st3"
        />
        <path
          d="M30.066 173.345a5.953 5.953 0 1 1-11.905 0 5.953 5.953 0 0 1 11.905 0z"
          className="st12"
        />
        <path
          d="M24.113 180.798c-4.109 0-7.452-3.343-7.452-7.453s3.343-7.453 7.452-7.453 7.453 3.343 7.453 7.453-3.343 7.453-7.453 7.453zm0-11.905c-2.455 0-4.452 1.998-4.452 4.453s1.997 4.453 4.452 4.453 4.453-1.998 4.453-4.453-1.998-4.453-4.453-4.453z"
          className="st3"
        />
        <path
          d="M82.023 173.345a5.953 5.953 0 1 1-11.905 0 5.953 5.953 0 0 1 11.905 0z"
          className="st12"
        />
        <path
          d="M76.07 180.798c-4.109 0-7.453-3.343-7.453-7.453s3.344-7.453 7.453-7.453 7.452 3.343 7.452 7.453-3.342 7.453-7.452 7.453zm0-11.905c-2.455 0-4.453 1.998-4.453 4.453s1.998 4.453 4.453 4.453 4.452-1.998 4.452-4.453-1.997-4.453-4.452-4.453z"
          className="st3"
        />
        <path
          d="M76.48 170.456c0 14.574-14.036 36.944-26.389 36.944-11.791 0-26.389-22.37-26.389-36.944 0-14.574 11.815-26.389 26.389-26.389 14.575 0 26.389 11.815 26.389 26.389z"
          className="st7"
        />
        <path
          d="M50.092 208.9c-12.93 0-27.889-23.265-27.889-38.444 0-15.378 12.511-27.889 27.889-27.889s27.889 12.511 27.889 27.889C77.98 185.985 63.29 208.9 50.092 208.9zm0-63.333c-13.724 0-24.889 11.165-24.889 24.889 0 13.468 13.904 35.444 24.889 35.444 11.299 0 24.889-21.77 24.889-35.444-.001-13.724-11.166-24.889-24.889-24.889z"
          className="st3"
        />
        <path
          d="M43.351 170.158a2.576 2.576 0 1 1-5.151 0 2.576 2.576 0 0 1 5.151 0zM61.984 170.158a2.576 2.576 0 1 1-5.151 0 2.576 2.576 0 0 1 5.151 0z"
          className="st3"
        />
        <path
          d="M40.064 177.815c0 1.832-1.986 3.317-4.437 3.317-2.45 0-4.437-1.485-4.437-3.317s1.986-3.317 4.437-3.317c2.451 0 4.437 1.485 4.437 3.317zM68.993 177.815c0 1.832-1.986 3.317-4.437 3.317-2.45 0-4.437-1.485-4.437-3.317s1.986-3.317 4.437-3.317c2.451 0 4.437 1.485 4.437 3.317z"
          className="st13"
        />
        <path
          d="M50.396 138.243c-15.085 0-27.313 11.691-27.313 26.114 0 1.526.276 2.663.789 4.085.242.67 4.153-1.469 4.486-1.704 1.665-1.173 3.026-2.762 4.043-4.519.421-.73.771-1.474 1.054-2.217.9-2.372 3.347-8.973 5.83-8.455 3.331.694 7.106 1.087 11.111 1.087s7.78-.392 11.111-1.087c2.484-.518 4.93 6.083 5.83 8.455.282.744.633 1.487 1.054 2.218 1.68 2.906 4.518 5.673 7.971 6.177.009-.167.219-.224.376-.281 1.13-.411.972-2.853.972-3.759 0-14.423-12.229-26.114-27.314-26.114z"
          className="st4"
        />
        <path
          d="M24.079 170.054c-.194 0-.373-.022-.532-.072a1.678 1.678 0 0 1-1.086-1.031c-.527-1.461-.878-2.802-.878-4.594 0-15.226 12.926-27.614 28.813-27.614S79.21 149.13 79.21 164.356l.003.283c.021 1.408.059 3.993-1.79 4.816a1.504 1.504 0 0 1-1.278.425c-3.458-.504-6.842-3.087-9.053-6.91a17.047 17.047 0 0 1-1.157-2.436l-.055-.145c-2.51-6.626-3.777-7.317-4.105-7.372-3.522.74-7.363 1.116-11.378 1.116-4.016 0-7.856-.376-11.417-1.119-.29.057-1.556.748-4.064 7.368l-.058.151c-.315.831-.704 1.65-1.156 2.435-1.178 2.035-2.726 3.762-4.479 4.996-.036.024-3.288 2.09-5.144 2.09zm52.527-1.891.54 1.399a.74.74 0 0 0 .099-.048l-.639-1.351zm1.253.315zm-27.463-28.735c-14.233 0-25.813 11.041-25.813 24.614 0 .928.118 1.677.333 2.447.906-.377 2.189-1.041 2.599-1.306 1.381-.972 2.628-2.371 3.589-4.03.37-.644.69-1.317.949-1.999l.057-.15c1.971-5.199 4.243-9.919 7.481-9.241 3.36.7 6.995 1.055 10.806 1.055 3.81 0 7.444-.355 10.805-1.055 3.243-.679 5.514 4.046 7.484 9.248l.054.143c.257.677.577 1.351.952 2.002 1.619 2.8 3.938 4.749 6.299 5.325l.055-.023c.128-.307.185-1.009.168-2.09l-.003-.327c-.001-13.572-11.581-24.613-25.815-24.613z"
          className="st3"
        />
        <path
          d="m34.647 210.883 5.387-4.06 10.395 13.79-5.388 4.06z"
          className="st4"
        />
        <path
          d="M45.031 226.177a1.501 1.501 0 0 1-1.198-.597l-10.394-13.791a1.498 1.498 0 0 1 .296-2.1l5.388-4.061a1.496 1.496 0 0 1 2.1.295l10.393 13.791a1.501 1.501 0 0 1-.295 2.101l-5.387 4.06a1.5 1.5 0 0 1-.903.302zm-8.293-14.996 8.588 11.395 2.991-2.254-8.587-11.395-2.992 2.254z"
          className="st3"
        />
        <path
          d="m55.772 224.675-5.388-4.06 10.395-13.791 5.387 4.06z"
          className="st4"
        />
        <path
          d="M55.761 226.177c-.314 0-.632-.098-.901-.302l-5.388-4.06a1.5 1.5 0 0 1-.296-2.1l10.393-13.791c.24-.318.596-.527.989-.583.398-.057.795.048 1.111.288l5.388 4.061a1.5 1.5 0 0 1 .296 2.1L56.96 225.58a1.498 1.498 0 0 1-1.199.597zm-3.286-5.855 2.992 2.254 8.588-11.395-2.992-2.255-8.588 11.396zM29.125 241.186a1.5 1.5 0 0 1-1.5-1.5v-10.835a1.5 1.5 0 1 1 3 0v10.835a1.5 1.5 0 0 1-1.5 1.5zM50.091 193.361c-4.033 0-6.941-1.814-8.646-5.393a1.5 1.5 0 0 1 2.709-1.29c1.196 2.513 3.083 3.684 5.937 3.684 3.339 0 5.351-2.132 5.888-3.565a1.5 1.5 0 0 1 2.809 1.053c-.995 2.653-4.142 5.511-8.697 5.511z"
          className="st3"
        />
        <path
          d="M239.433 225.976v13.709h-67.049v-13.709c0-8.355 6.773-15.128 15.128-15.128h10.001v-6.53h.17c2.753 1.939 5.563 3.081 8.226 3.081 2.776 0 5.636-1.131 8.401-3.047v6.496h9.995c8.356 0 15.128 6.772 15.128 15.128z"
          className="st7"
        />
        <path
          d="M239.434 241.185h-67.049a1.5 1.5 0 0 1-1.5-1.5v-13.71c0-9.169 7.459-16.628 16.628-16.628h8.501v-5.03a1.5 1.5 0 0 1 1.5-1.5h.17c.31 0 .611.096.864.274 2.645 1.863 5.121 2.808 7.361 2.808 2.346 0 4.885-.936 7.546-2.78a1.501 1.501 0 0 1 2.355 1.233v4.996h8.495c9.169 0 16.629 7.459 16.629 16.628v13.71a1.5 1.5 0 0 1-1.5 1.499zm-65.549-3h64.049v-12.21c0-7.515-6.114-13.628-13.629-13.628h-9.995a1.5 1.5 0 0 1-1.5-1.5v-3.824c-2.357 1.246-4.67 1.875-6.9 1.875-2.201 0-4.513-.663-6.895-1.977v3.925a1.5 1.5 0 0 1-1.5 1.5h-10.001c-7.515 0-13.628 6.113-13.628 13.628v12.211z"
          className="st3"
        />
        <path
          d="M239.434 225.971v13.715h-67.049v-13.715c0-8.356 6.773-15.128 15.128-15.128h3.002l-.062.045 10.396 13.788 5.365-4.042 5.365 4.042 10.396-13.788-.062-.045h2.391c8.357 0 15.13 6.773 15.13 15.128z"
          className="st14"
        />
        <path
          d="M239.434 241.186h-67.049a1.5 1.5 0 0 1-1.5-1.5v-13.715c0-9.169 7.459-16.628 16.628-16.628h3.002c.645 0 1.216.411 1.421 1.02l9.209 12.213 4.167-3.139a1.5 1.5 0 0 1 1.805-.001l4.168 3.14 9.289-12.32c.234-.54.743-.913 1.341-.913h2.391c9.169 0 16.629 7.459 16.629 16.628v13.715a1.501 1.501 0 0 1-1.501 1.5zm-65.549-3h64.049v-12.215c0-7.515-6.114-13.628-13.629-13.628h-1.547l-9.981 13.237a1.498 1.498 0 0 1-2.1.295l-4.463-3.362-4.462 3.362a1.5 1.5 0 0 1-2.1-.295l-9.981-13.237h-2.158c-7.515 0-13.628 6.114-13.628 13.628v12.215z"
          className="st3"
        />
        <path
          d="M185.884 173.345a5.953 5.953 0 1 1-11.905 0 5.953 5.953 0 0 1 11.905 0z"
          className="st12"
        />
        <path
          d="M179.932 180.798c-4.109 0-7.452-3.343-7.452-7.453s3.343-7.453 7.452-7.453 7.453 3.343 7.453 7.453-3.344 7.453-7.453 7.453zm0-11.905c-2.455 0-4.452 1.998-4.452 4.453s1.997 4.453 4.452 4.453 4.453-1.998 4.453-4.453-1.998-4.453-4.453-4.453z"
          className="st3"
        />
        <path
          d="M237.841 173.345a5.953 5.953 0 1 1-11.905 0 5.953 5.953 0 0 1 11.905 0z"
          className="st12"
        />
        <path
          d="M231.888 180.798c-4.109 0-7.452-3.343-7.452-7.453s3.343-7.453 7.452-7.453 7.453 3.343 7.453 7.453-3.344 7.453-7.453 7.453zm0-11.905c-2.455 0-4.452 1.998-4.452 4.453s1.997 4.453 4.452 4.453 4.453-1.998 4.453-4.453-1.998-4.453-4.453-4.453z"
          className="st3"
        />
        <path
          d="M232.299 170.456c0 14.574-14.036 36.944-26.389 36.944-11.791 0-26.389-22.37-26.389-36.944 0-14.574 11.815-26.389 26.389-26.389s26.389 11.815 26.389 26.389z"
          className="st7"
        />
        <path
          d="M205.91 208.9c-12.93 0-27.889-23.265-27.889-38.444 0-15.378 12.511-27.889 27.889-27.889s27.889 12.511 27.889 27.889c0 15.529-14.691 38.444-27.889 38.444zm0-63.333c-13.724 0-24.889 11.165-24.889 24.889 0 13.468 13.904 35.444 24.889 35.444 11.299 0 24.889-21.77 24.889-35.444 0-13.724-11.165-24.889-24.889-24.889z"
          className="st3"
        />
        <path
          d="M199.169 170.158a2.576 2.576 0 1 1-5.151 0 2.576 2.576 0 0 1 5.151 0zM217.802 170.158a2.576 2.576 0 1 1-5.151 0 2.576 2.576 0 0 1 5.151 0z"
          className="st3"
        />
        <path
          d="M195.882 177.815c0 1.832-1.986 3.317-4.437 3.317-2.45 0-4.437-1.485-4.437-3.317s1.986-3.317 4.437-3.317c2.451 0 4.437 1.485 4.437 3.317zM224.811 177.815c0 1.832-1.986 3.317-4.437 3.317-2.45 0-4.437-1.485-4.437-3.317s1.987-3.317 4.437-3.317c2.451 0 4.437 1.485 4.437 3.317z"
          className="st13"
        />
        <path
          d="M206.214 138.243c-15.085 0-27.313 11.691-27.313 26.114 0 1.526.276 2.663.789 4.085.242.67 4.153-1.469 4.486-1.704 1.665-1.173 3.026-2.762 4.043-4.519.421-.73.771-1.474 1.054-2.217.9-2.372 3.346-8.973 5.83-8.455 3.331.694 7.106 1.087 11.111 1.087 4.005 0 7.78-.392 11.111-1.087 2.484-.518 4.93 6.083 5.83 8.455.282.744.633 1.487 1.054 2.218 1.68 2.906 4.518 5.673 7.971 6.177.009-.167.219-.224.376-.281 1.13-.411.972-2.853.972-3.759 0-14.423-12.229-26.114-27.314-26.114z"
          className="st4"
        />
        <path
          d="M179.898 170.054c-.194 0-.373-.022-.532-.072a1.678 1.678 0 0 1-1.086-1.031c-.526-1.458-.878-2.798-.878-4.594 0-15.226 12.925-27.614 28.812-27.614s28.813 12.387 28.813 27.613l.003.284c.02 1.407.059 3.991-1.788 4.814a1.503 1.503 0 0 1-1.279.426c-3.459-.504-6.844-3.088-9.053-6.911a16.866 16.866 0 0 1-1.157-2.436l-.058-.151c-2.509-6.619-3.774-7.311-4.102-7.365-3.523.74-7.363 1.116-11.379 1.116-4.015 0-7.855-.376-11.416-1.119-.29.057-1.556.748-4.064 7.368l-.058.151c-.315.831-.704 1.65-1.156 2.435-1.18 2.036-2.727 3.763-4.48 4.996-.034.024-3.286 2.09-5.142 2.09zm52.526-1.891.542 1.398a.77.77 0 0 0 .097-.047l-.639-1.351zm-26.21-28.42c-14.233 0-25.812 11.041-25.812 24.614 0 .93.118 1.679.332 2.447.906-.377 2.19-1.041 2.6-1.306 1.379-.971 2.627-2.37 3.589-4.03.37-.643.69-1.316.949-1.998l.057-.15c1.971-5.199 4.246-9.919 7.481-9.241 3.36.7 6.995 1.055 10.805 1.055 3.811 0 7.445-.355 10.806-1.055 3.222-.678 5.511 4.042 7.482 9.241l.057.15c.259.682.579 1.355.951 2.001 1.619 2.801 3.938 4.751 6.3 5.327.018-.008.035-.016.054-.023.128-.307.185-1.008.168-2.089l-.003-.329c-.003-13.573-11.583-24.614-25.816-24.614z"
          className="st3"
        />
        <path
          d="m190.464 210.875 5.387-4.06 10.395 13.791-5.388 4.06z"
          className="st4"
        />
        <path
          d="M200.85 226.177c-.455 0-.904-.206-1.199-.597l-10.393-13.791a1.501 1.501 0 0 1 .295-2.101l5.387-4.061a1.496 1.496 0 0 1 2.101.295l10.394 13.791a1.499 1.499 0 0 1-.296 2.1l-5.388 4.06a1.48 1.48 0 0 1-.901.304zm-8.293-14.996 8.587 11.395 2.992-2.254-8.588-11.395-2.991 2.254z"
          className="st3"
        />
        <path
          d="m211.588 224.669-5.387-4.06 10.394-13.792 5.387 4.06z"
          className="st4"
        />
        <path
          d="M211.579 226.177c-.314 0-.632-.098-.901-.302l-5.388-4.06a1.5 1.5 0 0 1-.296-2.1l10.394-13.791c.24-.318.596-.527.989-.583a1.49 1.49 0 0 1 1.112.288l5.387 4.061c.661.498.793 1.439.295 2.101l-10.393 13.791a1.502 1.502 0 0 1-1.199.595zm-3.286-5.855 2.992 2.254 8.587-11.395-2.991-2.255-8.588 11.396zM227.485 241.186a1.5 1.5 0 0 1-1.5-1.5v-10.835a1.5 1.5 0 1 1 3 0v10.835a1.5 1.5 0 0 1-1.5 1.5zM205.909 193.361c-4.033 0-6.941-1.814-8.646-5.393a1.5 1.5 0 0 1 2.709-1.29c1.196 2.513 3.083 3.684 5.938 3.684 3.339 0 5.351-2.132 5.888-3.565a1.5 1.5 0 0 1 2.809 1.053c-.996 2.653-4.142 5.511-8.698 5.511z"
          className="st3"
        />
        <path
          d="M235.173 142.562s-12.756 13.392-28.491 13.392-21.401-5.996-21.401-13.392c0-7.396 5.666-13.392 21.401-13.392 15.734-.001 28.491 13.392 28.491 13.392z"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#87796f',
          }}
        />
        <path
          d="M206.681 157.454c-19.926 0-22.901-9.331-22.901-14.893 0-5.561 2.976-14.892 22.901-14.892 16.192 0 29.039 13.292 29.578 13.857a1.5 1.5 0 0 1 0 2.069c-.539.567-13.386 13.859-29.578 13.859zm0-26.785c-9.079 0-19.901 2.063-19.901 11.892 0 9.829 10.822 11.893 19.901 11.893 12.363 0 23.007-8.825 26.332-11.893-3.325-3.067-13.969-11.892-26.332-11.892zM156.39 241.186a1.5 1.5 0 0 1-1.5-1.5v-10.835a1.5 1.5 0 1 1 3 0v10.835a1.5 1.5 0 0 1-1.5 1.5z"
          className="st3"
        />
        <path d="M99.307 165.621h56.636v64.825H99.307z" className="st4" />
        <path
          d="M155.943 231.945H99.307a1.5 1.5 0 0 1-1.5-1.5V165.62a1.5 1.5 0 0 1 1.5-1.5h56.637a1.5 1.5 0 0 1 1.5 1.5v64.825a1.501 1.501 0 0 1-1.501 1.5zm-55.136-3h53.637V167.12h-53.637v61.825z"
          className="st3"
        />
        <path
          d="M161.524 239.686v-6.861c0-8.355-6.773-15.128-15.128-15.128h-9.995v-6.496c-2.764 1.917-5.625 3.047-8.401 3.047-2.663 0-5.473-1.142-8.226-3.082h-.17v6.53h-10.001c-8.355 0-15.128 6.773-15.128 15.128v6.861h67.049z"
          className="st7"
        />
        <path
          d="M161.524 241.186H94.476a1.5 1.5 0 0 1-1.5-1.5v-6.862c0-9.168 7.459-16.628 16.628-16.628h8.501v-5.03a1.5 1.5 0 0 1 1.5-1.5h.17c.31 0 .611.096.863.273 2.646 1.863 5.123 2.808 7.362 2.808 2.346 0 4.885-.936 7.546-2.78a1.5 1.5 0 0 1 2.355 1.232v4.996h8.495c9.169 0 16.629 7.46 16.629 16.628v6.862a1.502 1.502 0 0 1-1.501 1.501zm-65.548-3h64.049v-5.362c0-7.515-6.114-13.628-13.629-13.628H136.4a1.5 1.5 0 0 1-1.5-1.5v-3.824c-2.357 1.246-4.67 1.876-6.9 1.876-2.201 0-4.512-.663-6.896-1.977v3.925a1.5 1.5 0 0 1-1.5 1.5h-10.001c-7.515 0-13.628 6.113-13.628 13.628v5.362z"
          className="st3"
        />
        <path
          d="M161.524 239.686v-6.866c0-8.355-6.773-15.129-15.128-15.129h-2.391l.062.045-10.396 13.788-5.365-4.042-5.365 4.042-10.396-13.788.062-.045h-3.002c-8.356 0-15.128 6.773-15.128 15.129v6.866h67.047z"
          style={{
            fill: '#f16c7a',
          }}
        />
        <path
          d="M161.524 241.186H94.476a1.5 1.5 0 0 1-1.5-1.5v-6.866c0-9.169 7.459-16.629 16.628-16.629h3.003c.646 0 1.217.412 1.422 1.023l9.207 12.211 4.168-3.14a1.499 1.499 0 0 1 1.805.001l4.167 3.139 9.289-12.318c.233-.542.742-.916 1.341-.916h2.392c9.169 0 16.628 7.46 16.628 16.629v6.866a1.503 1.503 0 0 1-1.502 1.5zm-65.548-3h64.049v-5.366c0-7.515-6.113-13.629-13.628-13.629h-1.547l-9.982 13.237a1.497 1.497 0 0 1-2.1.294l-4.462-3.362-4.463 3.362a1.5 1.5 0 0 1-2.1-.295l-9.982-13.237h-2.158c-7.515 0-13.628 6.114-13.628 13.629v5.367z"
          className="st3"
        />
        <path
          d="M107.975 180.195a5.953 5.953 0 1 1-11.905 0 5.953 5.953 0 0 1 11.905 0z"
          className="st12"
        />
        <path
          d="M102.022 187.647c-4.109 0-7.452-3.343-7.452-7.453 0-4.109 3.343-7.453 7.452-7.453s7.453 3.343 7.453 7.453c.001 4.11-3.343 7.453-7.453 7.453zm0-11.905a4.458 4.458 0 0 0-4.452 4.453 4.458 4.458 0 0 0 4.452 4.453 4.459 4.459 0 0 0 4.453-4.453 4.457 4.457 0 0 0-4.453-4.453z"
          className="st3"
        />
        <path
          d="m112.535 217.738 5.388-4.06 10.392 13.79-5.387 4.06z"
          className="st4"
        />
        <path
          d="M122.94 233.026c-.455 0-.904-.206-1.199-.597l-10.393-13.79a1.501 1.501 0 0 1 .295-2.101l5.387-4.06a1.499 1.499 0 0 1 2.101.295l10.394 13.79a1.499 1.499 0 0 1-.296 2.1l-5.388 4.06a1.48 1.48 0 0 1-.901.303zm-8.293-14.996 8.587 11.395 2.992-2.254-8.588-11.395-2.991 2.254z"
          className="st3"
        />
        <path
          d="m133.68 231.52-5.388-4.06 10.394-13.79 5.387 4.06z"
          className="st4"
        />
        <path
          d="M133.67 233.026c-.314 0-.632-.098-.901-.302l-5.388-4.06a1.5 1.5 0 0 1-.296-2.1l10.394-13.79a1.499 1.499 0 0 1 2.101-.295l5.388 4.06a1.5 1.5 0 0 1 .296 2.1l-10.394 13.79a1.501 1.501 0 0 1-1.2.597zm-3.286-5.855 2.992 2.254 8.588-11.395-2.992-2.254-8.588 11.395z"
          className="st3"
        />
        <path
          d="M159.917 180.064a5.953 5.953 0 1 1-11.905 0 5.953 5.953 0 0 1 11.905 0z"
          className="st12"
        />
        <path
          d="M153.964 187.517c-4.109 0-7.452-3.343-7.452-7.453 0-4.109 3.343-7.453 7.452-7.453s7.453 3.343 7.453 7.453c0 4.109-3.344 7.453-7.453 7.453zm0-11.906a4.458 4.458 0 0 0-4.452 4.453 4.458 4.458 0 0 0 4.452 4.453 4.459 4.459 0 0 0 4.453-4.453 4.459 4.459 0 0 0-4.453-4.453z"
          className="st3"
        />
        <path
          d="M154.389 177.305c0 14.574-14.036 36.944-26.389 36.944-11.791 0-26.389-22.37-26.389-36.944 0-14.574 11.815-26.389 26.389-26.389 14.575 0 26.389 11.815 26.389 26.389z"
          className="st7"
        />
        <path
          d="M128.001 215.749c-12.93 0-27.889-23.265-27.889-38.444 0-15.378 12.511-27.889 27.889-27.889s27.889 12.511 27.889 27.889c0 15.529-14.691 38.444-27.889 38.444zm0-63.333c-13.724 0-24.889 11.165-24.889 24.889 0 13.468 13.904 35.444 24.889 35.444 11.299 0 24.889-21.77 24.889-35.444 0-13.724-11.165-24.889-24.889-24.889z"
          className="st3"
        />
        <path
          d="M121.26 177.007a2.576 2.576 0 1 1-5.151 0 2.576 2.576 0 0 1 5.151 0zM139.893 177.007a2.576 2.576 0 1 1-5.151 0 2.576 2.576 0 0 1 5.151 0z"
          className="st3"
        />
        <path
          d="M117.973 184.665c0 1.832-1.986 3.317-4.437 3.317-2.45 0-4.437-1.485-4.437-3.317s1.987-3.317 4.437-3.317c2.451 0 4.437 1.485 4.437 3.317zM146.902 184.665c0 1.832-1.986 3.317-4.437 3.317-2.45 0-4.437-1.485-4.437-3.317s1.986-3.317 4.437-3.317c2.451 0 4.437 1.485 4.437 3.317z"
          className="st13"
        />
        <path
          d="M128 200.21c-4.033 0-6.942-1.815-8.646-5.394a1.502 1.502 0 0 1 .71-2 1.499 1.499 0 0 1 1.999.71c1.196 2.513 3.083 3.684 5.938 3.684 3.339 0 5.351-2.132 5.888-3.565a1.5 1.5 0 1 1 2.809 1.053c-.996 2.654-4.142 5.512-8.698 5.512z"
          className="st3"
        />
        <path
          d="M158.033 167.633a30.31 30.31 0 0 1-1.017 7.819c-6.759-3.258-13.188-7.259-19.145-11.781a84.305 84.305 0 0 1-5.11-4.165c-2.017-1.769-3.924-3.676-5.644-5.742-3.742-4.506-6.49-9.682-8.814-15.073a30.178 30.178 0 0 1 9.32-1.462c8.699 0 16.546 3.654 22.09 9.512a30.268 30.268 0 0 1 8.32 20.892z"
          className="st4"
        />
        <path
          d="M157.017 176.953c-.223 0-.445-.05-.651-.149-6.683-3.221-13.21-7.238-19.401-11.938-1.976-1.498-3.677-2.885-5.2-4.239-2.173-1.907-4.127-3.896-5.799-5.903-3.38-4.07-6.252-8.975-9.038-15.439a1.497 1.497 0 0 1 .915-2.02 31.664 31.664 0 0 1 9.781-1.535c8.857 0 17.088 3.544 23.18 9.98a31.746 31.746 0 0 1 8.73 21.924c0 2.789-.359 5.549-1.067 8.206a1.501 1.501 0 0 1-1.45 1.113zm-36.65-37.303c2.454 5.432 4.986 9.642 7.904 13.155 1.571 1.887 3.415 3.762 5.479 5.573a83.449 83.449 0 0 0 5.027 4.098c5.511 4.183 11.291 7.809 17.206 10.794.365-1.844.55-3.733.55-5.637a28.76 28.76 0 0 0-7.909-19.861c-5.519-5.832-12.977-9.044-21.001-9.044a28.896 28.896 0 0 0-7.256.922z"
          className="st3"
        />
        <path
          d="M149.713 146.741c-5.231 4.77-10.924 8.99-16.952 12.765-10.688 6.699-22.409 11.985-34.405 16.386a30.336 30.336 0 0 1-1.138-8.259c0-13.54 8.853-25.014 21.085-28.943a30.178 30.178 0 0 1 9.32-1.462c8.699.001 16.546 3.655 22.09 9.513z"
          className="st4"
        />
        <path
          d="M98.356 177.392a1.5 1.5 0 0 1-1.444-1.092 31.842 31.842 0 0 1-1.194-8.667c0-13.915 8.892-26.12 22.126-30.371a31.688 31.688 0 0 1 9.778-1.533c8.857 0 17.088 3.544 23.18 9.98a1.501 1.501 0 0 1-.079 2.139c-5.058 4.613-10.834 8.962-17.167 12.928-9.831 6.162-21.177 11.567-34.684 16.523a1.453 1.453 0 0 1-.516.093zm29.267-38.663c-3.033 0-6.014.467-8.857 1.388-11.992 3.852-20.047 14.91-20.047 27.516a28.9 28.9 0 0 0 .684 6.271c12.623-4.724 23.289-9.857 32.562-15.67 5.699-3.568 10.93-7.451 15.571-11.555-5.392-5.138-12.405-7.95-19.913-7.95z"
          className="st3"
        />
        <path
          d="M64.253 62.678H36.747A3.747 3.747 0 0 1 33 58.931V37.425a3.747 3.747 0 0 1 3.747-3.747h27.507a3.747 3.747 0 0 1 3.747 3.747v21.507a3.748 3.748 0 0 1-3.748 3.746z"
          className="st7"
        />
        <path
          d="M64.253 64.178H36.746a5.252 5.252 0 0 1-5.246-5.247V37.425a5.252 5.252 0 0 1 5.246-5.247h27.507a5.253 5.253 0 0 1 5.247 5.247v21.507a5.252 5.252 0 0 1-5.247 5.246zm-27.507-29a2.25 2.25 0 0 0-2.246 2.247v21.507a2.25 2.25 0 0 0 2.246 2.247h27.507a2.25 2.25 0 0 0 2.247-2.247V37.425a2.25 2.25 0 0 0-2.247-2.247H36.746zM68 77.178H33a1.5 1.5 0 1 1 0-3h35a1.5 1.5 0 1 1 0 3zM68 93.178H33a1.5 1.5 0 1 1 0-3h35a1.5 1.5 0 1 1 0 3zM68 109.178H33a1.5 1.5 0 1 1 0-3h35a1.5 1.5 0 1 1 0 3z"
          className="st3"
        />
        <path
          d="M219.253 62.678h-27.507a3.747 3.747 0 0 1-3.747-3.747V37.425a3.747 3.747 0 0 1 3.747-3.747h27.507A3.747 3.747 0 0 1 223 37.425v21.507a3.746 3.746 0 0 1-3.747 3.746z"
          className="st7"
        />
        <path
          d="M219.253 64.178h-27.506a5.253 5.253 0 0 1-5.247-5.247V37.425a5.253 5.253 0 0 1 5.247-5.247h27.506a5.253 5.253 0 0 1 5.247 5.247v21.507a5.252 5.252 0 0 1-5.247 5.246zm-27.506-29a2.25 2.25 0 0 0-2.247 2.247v21.507a2.25 2.25 0 0 0 2.247 2.247h27.506a2.25 2.25 0 0 0 2.247-2.247V37.425a2.25 2.25 0 0 0-2.247-2.247h-27.506zM223 77.178h-35a1.5 1.5 0 1 1 0-3h35a1.5 1.5 0 1 1 0 3zM223 93.178h-35a1.5 1.5 0 1 1 0-3h35a1.5 1.5 0 1 1 0 3zM223 109.178h-35a1.5 1.5 0 1 1 0-3h35a1.5 1.5 0 1 1 0 3z"
          className="st3"
        />
        <path
          d="M222.39 17.818h-15c8.61 0 15.61 7 15.61 15.6v.34c.93.95 1.5 2.24 1.5 3.67v21.5c0 1.43-.57 2.72-1.5 3.67v11.58c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5v13c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5v13c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5v22.72c7.77 3.94 12.92 9.27 13.26 9.63.55.58.55 1.49 0 2.07-.22.23-2.49 2.58-6.2 5.28 3.13 4.42 4.97 9.75 4.97 15.48v.28c.01.53.02 1.22-.07 1.93.9.4 1.69.98 2.36 1.69.44-1.44.68-2.97.68-4.55V33.418c0-8.6-7-15.6-15.61-15.6z"
          style={{
            fill: '#b9c239',
          }}
        />
      </g>
    </svg>
  );
}
export default SvgComponent;
