import React from 'react';
// Sections
import TopNavbar from '../components/Nav/TopNavbar';
import Header from '../components/Sections/Header';
import Study from '../components/Sections/Study';
import Services from '../components/Sections/Services';
import Reviews from '../components/Sections/Reviews';
import Pricing from '../components/Sections/Pricing';
import Contact from '../components/Sections/Contact';
import Footer from '../components/Sections/Footer';
import FaqSection from '../components/Sections/FaqSection';

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Study />
      <Services />
      <Reviews />
      <Pricing />
      <FaqSection />
      <Contact />
      <Footer />
    </>
  );
}
