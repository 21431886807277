import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Components
import { useTranslation } from 'react-i18next';
import Sidebar from '../Nav/Sidebar';
import Backdrop from '../Elements/Backdrop';
// Assets
import logoIcon from '../../assets/img/logo/logo3.png';
import BurgerIcon from '../../assets/svg/BurgerIcon';
import i18n from '../../core/i18n';
import { topNavigationItems } from '../../constants/navigation';

const renderNavigation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'topNavBar' });
  return topNavigationItems.map((item) => {
    return (
      <li className="semiBold font pointer">
        <Link
          activeClass="active"
          style={{ padding: '10px 15px' }}
          to={item}
          spy
          smooth
          offset={-80}
        >
          {t(item)}
        </Link>
      </li>
    );
  });
};
export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY));
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: '60px' } : { height: '80px' }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth>
            <img
              src={logoIcon}
              alt="Logo"
              style={{ height: 'auto', width: 50 }}
            />
            <h1 style={{ marginLeft: '15px' }} className="font20 extraBold">
              {i18n.t('core.deutschler')}
            </h1>
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper
            className="flexNullC
          enter"
          >
            {renderNavigation()}
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
