import React from 'react';
import styled from 'styled-components';
// Components
import FullButton from '../Buttons/FullButton';
// Assets
import Individual from '../../assets/svg/pricing/Individual';
import Group from '../../assets/svg/pricing/Group';
import Exams from '../../assets/svg/pricing/Exams';
import Couple from '../../assets/svg/pricing/Couple';

import CheckMark from '../../assets/svg/Checkmark';
import i18n from '../../core/i18n';

export default function PricingTable({ icon, price, title, subtitle, offers }) {
  let getIcon;
  switch (icon) {
    case 'group':
      getIcon = <Group />;
      break;
    case 'individual':
      getIcon = <Individual />;
      break;
    case 'exams':
      getIcon = <Exams />;
      break;
    case 'couple':
      getIcon = <Couple />;
      break;
    default:
      getIcon = <Group />;
      break;
  }

  const openInstagram = () => {
    const instagramUrl = 'https://www.instagram.com/deutschler_ua/';
    window.open(instagramUrl, '_blank');
  };

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <Header className="row flexSpaceCenter">
        <IconStyle>{getIcon}</IconStyle>
        <Price className="font18 extraBold">{price}</Price>
      </Header>
      <div style={{ margin: '10px 0' }}>
        <Title className="font25 extraBold">{title}</Title>
      </div>
      <div>
        {!!subtitle && <Title className="font16 extraBold">{subtitle}</Title>}
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
            <div
              className="flexNullCenter"
              style={{ margin: '15px 0' }}
              key={index}
            >
              <div
                style={{
                  position: 'relative',
                  top: '-1px',
                  marginRight: '15px'
                }}
              >
                {item.checked ? (
                  <div style={{ minWidth: '20px' }}>
                    <CheckMark />
                  </div>
                ) : (
                  <div style={{ minWidth: '20px' }} />
                )}
              </div>
              <Text className="15 extraBold">{item.name}</Text>
            </div>
          ))
          : null}
      </div>
      <div style={{ maxWidth: '200px', margin: '30px auto 0 auto' }}>
        <FullButton title={i18n.t('pricing.order')} action={openInstagram} />
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
    width: 100%;
    text-align: left;
    padding: 20px 30px;
    margin-top: 30px;
`;

const IconStyle = styled.div`
  height: 50px;
  width: 50px;
`;

const Header = styled.div`
  margin: 0px;
`;

const Price = styled.p`
  @media (max-width: 860px) {
    font-size: 25px;
  }
`;

const Text = styled.p`
  @media (max-width: 860px) {
    font-size: 20px;
  }
`;

const Title = styled.h4`
  @media (max-width: 860px) {
    font-size: 25px;
  }
`;

