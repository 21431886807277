import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Assets
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../assets/svg/CloseIcon';
import i18n from '../../core/i18n';
import logoIcon from '../../assets/img/logo/logo3.png';
import { topNavigationItems } from '../../constants/navigation';
import { colors } from '../../constants/theme';

const renderNavigation = (sidebarOpen, toggleSidebar) => {
  const { t } = useTranslation('translation', { keyPrefix: 'topNavBar' });
  return topNavigationItems.map((item) => {
    return (
      <li className="semiBold font30 pointer">
        <Link
          onClick={() => toggleSidebar(!sidebarOpen)}
          activeClass="active"
          className="whiteColor"
          style={{ padding: '10px 15px' }}
          to={item}
          spy
          smooth
          offset={-60}
        >
          {t(item)}
        </Link>
      </li>
    );
  });
};
export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img
            src={logoIcon}
            alt="Logo"
            style={{ height: 'auto', width: 50 }}
          />
          <h1 className="whiteColor font50" style={{ marginLeft: '15px' }}>
            {i18n.t('core.deutschler')}
          </h1>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        {renderNavigation(sidebarOpen, toggleSidebar)}
      </UlStyle>
    </Wrapper>
  );
}
const Wrapper = styled.nav<any>`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? '0px' : '-400px')};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
  background: ${colors.mainBlue};
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
