import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M503.117 92.802c.009-.583-.132-1.112-.238-1.677-.097-.556-.15-1.103-.353-1.633-.23-.6-.618-1.103-.971-1.642-.247-.362-.362-.777-.662-1.103l-60.716-67.275a8.827 8.827 0 0 0-12.473-.636c-3.619 3.266-3.902 8.845-.636 12.465l57.035 63.188-33.262 83.165c-1.818 4.529.388 9.666 4.917 11.485a8.87 8.87 0 0 0 3.275.627c3.505 0 6.824-2.101 8.201-5.553l35.31-88.276c.168-.424.141-.865.238-1.298.141-.619.326-1.21.335-1.837M379.586 507.556a8.826 8.826 0 0 1-8.828-8.828c0-.335 0-.786-.883-1.624-6.321-6.047-28.893-7.865-42.849-7.212h-.071c-34.763 0-58.492-7.027-70.956-20.895-12.465 13.877-36.228 20.904-70.621 20.904-14.354-.662-36.935 1.156-43.255 7.203-.883.839-.883 1.289-.883 1.624a8.826 8.826 0 0 1-8.828 8.828 8.826 8.826 0 0 1-8.828-8.828c0-5.455 2.198-10.443 6.356-14.398 13.956-13.338 48.975-12.385 55.852-12.076 37.632-.009 51.862-9.366 56.956-14.936 3.143-3.443 4.114-6.709 4.387-8.66-.124-2.26.071-3.778.132-4.193.644-4.326 4.361-7.53 8.73-7.53s8.086 3.204 8.73 7.53c.062.424.265 1.977.124 4.273.291 1.889 1.262 5.155 4.396 8.58 5.094 5.57 19.324 14.927 57.37 14.927 6.462-.3 41.481-1.236 55.437 12.085 4.158 3.955 6.356 8.942 6.356 14.398a8.82 8.82 0 0 1-8.824 8.828"
        style={{
          fill: '#e0e2e3',
        }}
      />
      <path
        d="M17.655 445.762h476.69V251.556H17.655v194.206z"
        style={{
          fill: '#b88353',
        }}
      />
      <path
        d="M17.655 498.728h35.31v-52.966h-35.31v52.966zM459.034 498.728h35.31v-52.966h-35.31v52.966zM0 251.556h512v-35.31H0v35.31z"
        style={{
          fill: '#8c5d37',
        }}
      />
      <path
        d="M123.586 216.245H17.655C7.901 216.245 0 208.344 0 198.59c0-9.755 7.901-17.655 17.655-17.655h105.931c-11.776 9.798-11.767 25.6 0 35.31"
        style={{
          fill: '#d75a4a',
        }}
      />
      <path
        d="M141.241 180.935H35.31c-9.754 0-17.655-7.901-17.655-17.655s7.901-17.655 17.655-17.655h105.931c-11.776 9.798-11.767 25.6 0 35.31"
        style={{
          fill: '#ecba16',
        }}
      />
      <path
        d="M335.448 216.245H176.552l14.627-157.228c1.006-10.876 7.442-20.259 16.393-26.968 9.119-6.824 20.542-9.825 31.929-9.825h37.932c9.26 0 18.582 2.083 26.341 7.124 10.408 6.762 17.814 17.24 18.953 29.67l12.721 157.227z"
        style={{
          fill: '#7383bf',
        }}
      />
      <path
        d="M494.345 216.245h-70.621V198.97c0-9.958 8.077-18.035 18.035-18.035h34.551c9.958 0 18.035 8.077 18.035 18.035v17.275zM445.506 57.192l-15.607 15.607-43.697-43.697 15.607-15.607c12.067-12.067 31.629-12.067 43.697 0s12.068 31.629 0 43.697"
        style={{
          fill: '#b0b6bb',
        }}
      />
      <path
        d="M410.921 401.624h-309.84c-16.825 0-30.464-13.639-30.464-30.455v-45.021c0-16.817 13.639-30.455 30.464-30.455H410.92c16.825 0 30.455 13.639 30.455 30.455v45.021c.001 16.817-13.629 30.455-30.454 30.455"
        style={{
          fill: '#8c5d37',
        }}
      />
    </svg>
  );
}
export default SvgComponent;
