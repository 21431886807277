import React from 'react';
import styled from 'styled-components';
// Assets
import QuoteIcon from '../../assets/svg/Quotes';
import { colors } from '../../constants/theme';

export default function TestimonialBox({ text, author }) {
  return (
    <Wrapper className="radius8 flexNullCenter flexColumn">
      <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper>
      <TextStyle
        className="whiteColor font14"
        style={{ paddingBottom: '30px' }}
      >
        {text}
      </TextStyle>
      <TextStyle className="darkColor font14" style={{ alignSelf: 'flex-end' }}>
        <em>{author}</em>
      </TextStyle>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
  background: ${colors.mainBlue};
`;
const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;

const TextStyle = styled.p`
  @media (max-width: 860px) {
    font-size: 18px;
  }
`;
