import React from 'react';
import styled from 'styled-components';

import TestimonialSlider from '../Elements/TestimonialSlider';
import i18n from '../../core/i18n';

export default function Reviews() {
  return (
    <Wrapper id="reviews">
      <div className="lightBg" style={{ padding: '50px 0' }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{i18n.t('reviews.title')}</h1>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
