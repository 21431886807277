import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
// Assets
import { useTranslation } from 'react-i18next';
import logoIcon from '../../assets/img/logo/logo3.png';
import { colors } from '../../constants/theme';
import i18n from '../../core/i18n';

export default function Contact() {
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <Wrapper>
      <div style={{ background: colors.mainBlue }}>
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: '30px 0' }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth
              offset={-80}
            >
              <img
                src={logoIcon}
                alt="Logo"
                style={{ height: 80, width: 'auto' }}
              />
              <h1
                className="font25 extraBold whiteColor"
                style={{ marginLeft: '15px' }}
              >
                {i18n.t('core.deutschler')}
              </h1>
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} {t('company')}
            </StyleP>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
