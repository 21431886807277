import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/theme';

export default function FullButton({ title, action, border }: any) {
  return (
    <Wrapper
      className="animate pointer radius8 font18"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}
const Wrapper = styled.button<any>`
  border: 1px solid ${(props) => (props.border ? '#707070' : colors.mainBlue)};
  background-color: ${(props) =>
    props.border ? 'transparent' : colors.mainBlue};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? '#707070' : '#fff')};
  :hover {
    background-color: ${(props) =>
      props.border ? 'transparent' : colors.mainBlue};
    border: 1px solid ${colors.mainBlue};
    color: ${(props) => (props.border ? colors.mainBlue : '#fff')};
  } 
    @media (max-width: 960px) {
        font-size: 1.5rem;
    }
`;
