import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: 'new 0 0 470 470',
      }}
      viewBox="0 0 470 470"
      {...props}
    >
      <path
        d="M315.393 312.75c-21.88-11.793-46.62-18.931-72.893-20.077V315c0 4.143-3.358 7.5-7.5 7.5s-7.5-3.357-7.5-7.5v-22.327c-26.272 1.146-51.013 8.284-72.893 20.077l72.893 72.894V345c0-4.143 3.358-7.5 7.5-7.5s7.5 3.357 7.5 7.5v40.644l72.893-72.894z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M153.75 156.25c0 44.802 36.449 81.25 81.25 81.25s81.25-36.448 81.25-81.25c0-8.416-1.284-16.464-3.891-24.514-9.108-4.539-19.365-6.933-29.85-6.933-15.563 0-30.732 5.49-42.712 15.459-1.39 1.156-3.094 1.734-4.797 1.734s-3.407-.578-4.797-1.734c-11.979-9.969-27.148-15.459-42.712-15.459-10.485 0-20.742 2.395-29.85 6.933-2.607 8.05-3.891 16.098-3.891 24.514z"
        style={{
          fill: '#f2ebd9',
        }}
      />
      <path
        d="M139.249 304.258C167.199 287.283 199.978 277.5 235 277.5s67.801 9.783 95.751 26.758a7.437 7.437 0 0 1 1.704 1.061 186.007 186.007 0 0 1 21.295 15.443V282.5a7.5 7.5 0 0 1-7.5-7.5V156.25c0-23.276-7.09-45.43-20.25-64.037v33.045c3.518 10.175 5.25 20.329 5.25 30.992 0 53.072-43.178 96.25-96.25 96.25s-96.25-43.178-96.25-96.25c0-10.663 1.732-20.817 5.25-30.992V92.213c-13.16 18.606-20.25 40.76-20.25 64.037V275a7.5 7.5 0 0 1-7.5 7.5v38.263a186.007 186.007 0 0 1 21.295-15.443 7.429 7.429 0 0 1 1.704-1.062z"
        style={{
          fill: '#ffcc75',
        }}
      />
      <path
        d="M159 114.834c9.013-3.311 18.68-5.031 28.491-5.031 17.04 0 33.69 5.371 47.509 15.229 13.819-9.858 30.47-15.229 47.509-15.229 9.811 0 19.478 1.721 28.491 5.031V47.5a7.5 7.5 0 0 1 7.5-7.5V25H159v89.834z"
        style={{
          fill: '#6da8d6',
        }}
      />
      <path
        d="M123.75 275V156.25c0-23.276 7.09-45.43 20.25-64.037v33.045c-3.518 10.175-5.25 20.329-5.25 30.992 0 53.072 43.178 96.25 96.25 96.25s96.25-43.178 96.25-96.25c0-10.663-1.732-20.817-5.25-30.992V92.213c13.16 18.606 20.25 40.76 20.25 64.037V275c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5V156.25c0-32.921-12.493-63.87-35.25-87.503V47.5c0-4.143-3.358-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v67.334c-9.013-3.311-18.68-5.031-28.491-5.031-17.04 0-33.69 5.371-47.509 15.229-13.819-9.858-30.47-15.229-47.509-15.229-9.811 0-19.478 1.721-28.491 5.031V25h226v129.758l-10.91 26.339a7.5 7.5 0 0 0 4.059 9.799 7.497 7.497 0 0 0 9.799-4.059l4.551-10.988 4.551 10.988a7.501 7.501 0 0 0 6.932 4.632 7.5 7.5 0 0 0 6.926-10.372L400 154.758V25h12.5c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5H400V7.5c0-4.143-3.358-7.5-7.5-7.5S385 3.357 385 7.5V10H57.5c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5H144v43.747c-22.757 23.633-35.25 54.582-35.25 87.503V275c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5zm63.741-150.197c15.563 0 30.732 5.49 42.712 15.459 1.39 1.156 3.094 1.734 4.797 1.734s3.407-.578 4.797-1.734c11.979-9.969 27.148-15.459 42.712-15.459 10.485 0 20.742 2.395 29.85 6.933 2.607 8.05 3.891 16.099 3.891 24.514 0 44.802-36.449 81.25-81.25 81.25s-81.25-36.448-81.25-81.25c0-8.416 1.284-16.464 3.891-24.514 9.108-4.539 19.365-6.933 29.85-6.933z"
        style={{
          fill: '#082947',
        }}
      />
      <path
        d="M137.5 455h267.337c-2.444-56.051-32.163-105.136-76.177-134.303l-88.356 88.357c-.018.018-.038.033-.057.051a7.503 7.503 0 0 1-.486.44c-.054.044-.112.082-.167.125-.139.107-.277.215-.423.313-.049.033-.101.06-.151.091a7.253 7.253 0 0 1-.48.288c-.037.02-.075.035-.112.054a7.733 7.733 0 0 1-.552.261c-.026.011-.054.019-.081.029-.2.08-.402.155-.609.218-.026.008-.054.013-.08.021a6.989 6.989 0 0 1-.63.158c-.059.012-.119.017-.178.027a7.64 7.64 0 0 1-.549.082 7.468 7.468 0 0 1-1.498 0 7.557 7.557 0 0 1-.549-.082c-.059-.01-.119-.016-.178-.027a7.342 7.342 0 0 1-.63-.158c-.026-.008-.054-.013-.08-.021a7.329 7.329 0 0 1-.609-.218c-.027-.011-.054-.019-.081-.029a7.733 7.733 0 0 1-.552-.261c-.037-.019-.076-.035-.112-.054a6.846 6.846 0 0 1-.48-.288c-.05-.031-.102-.059-.151-.091-.146-.098-.285-.206-.423-.313-.055-.043-.113-.081-.167-.125a7.503 7.503 0 0 1-.486-.44c-.018-.018-.039-.033-.057-.051l-88.356-88.357C97.326 349.864 67.607 398.949 65.163 455H107.5a7.5 7.5 0 0 1 7.5 7.5h15a7.5 7.5 0 0 1 7.5-7.5z"
        style={{
          fill: '#185f8d',
        }}
      />
      <path
        d="M353.75 320.763a186.007 186.007 0 0 0-21.295-15.443 7.437 7.437 0 0 0-1.704-1.061C302.801 287.283 270.022 277.5 235 277.5s-67.801 9.783-95.751 26.758a7.437 7.437 0 0 0-1.704 1.061 186.007 186.007 0 0 0-21.295 15.443C75.778 354.726 50 405.655 50 462.5a7.5 7.5 0 0 0 7.5 7.5h50c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5H65.163c2.444-56.051 32.163-105.136 76.177-134.303l88.356 88.357c.018.018.038.033.057.051.156.153.317.301.486.44.054.044.112.082.167.125.139.107.277.215.423.313.049.033.101.06.151.091.157.101.315.199.48.288.037.02.075.035.112.054.181.093.363.182.552.261.026.011.054.019.081.029.2.08.402.155.609.218.026.008.054.013.08.021.207.061.416.116.63.158.059.012.119.017.178.027.182.032.363.063.549.082a7.468 7.468 0 0 0 1.498 0c.186-.019.368-.05.549-.082.059-.01.119-.016.178-.027.214-.042.423-.098.63-.158.026-.008.054-.013.08-.021.208-.063.41-.138.609-.218.027-.011.054-.019.081-.029.189-.079.371-.168.552-.261.037-.019.076-.035.112-.054.165-.088.323-.187.48-.288.05-.031.102-.059.151-.091.146-.098.285-.206.423-.313.055-.043.113-.081.167-.125.169-.139.33-.287.486-.44.018-.018.039-.033.057-.051l88.356-88.357c44.014 29.167 73.733 78.252 76.177 134.303H137.5c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h275a7.5 7.5 0 0 0 7.5-7.5c0-56.845-25.778-107.774-66.25-141.737zM235 337.5a7.5 7.5 0 0 0-7.5 7.5v40.644l-72.893-72.894c21.88-11.793 46.62-18.931 72.893-20.077V315c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-22.327c26.272 1.146 51.013 8.284 72.893 20.077L242.5 385.644V345a7.5 7.5 0 0 0-7.5-7.5z"
        style={{
          fill: '#082947',
        }}
      />
    </svg>
  );
}
export default SvgComponent;
