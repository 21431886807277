import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <g data-name="25_Video Lession">
        <path
          d="M38 56H26a1 1 0 0 1-1-1V45a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1Z"
          style={{
            fill: '#dfeaef',
          }}
        />
        <path
          d="M27 56V46a1 1 0 0 1 1-1h-2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2a1 1 0 0 1-1-1Z"
          style={{
            fill: '#c3d6dd',
          }}
        />
        <path
          d="M51 62H13a1 1 0 0 1-1-1 7.009 7.009 0 0 1 7-7h26a7.009 7.009 0 0 1 7 7 1 1 0 0 1-1 1Z"
          style={{
            fill: '#0455bf',
          }}
        />
        <path
          d="M14 61a7.009 7.009 0 0 1 7-7h-2a7.009 7.009 0 0 0-7 7 1 1 0 0 0 1 1h2a1 1 0 0 1-1-1Z"
          style={{
            fill: '#004787',
          }}
        />
        <path
          d="M59 46H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v38a3 3 0 0 1-3 3Z"
          style={{
            fill: '#fdfeff',
          }}
        />
        <path
          d="M4 43V5a3 3 0 0 1 3-3H5a3 3 0 0 0-3 3v38a3 3 0 0 0 3 3h2a3 3 0 0 1-3-3Z"
          style={{
            fill: '#dfeaef',
          }}
        />
        <path
          d="M59 46H5a3 3 0 0 1-3-3v-5h60v5a3 3 0 0 1-3 3Z"
          style={{
            fill: '#0455bf',
          }}
        />
        <path
          d="M4 44v-6H2v6a2 2 0 0 0 2 2h2a2 2 0 0 1-2-2Z"
          style={{
            fill: '#004787',
          }}
        />
        <path
          d="M31 41h2v2h-2z"
          style={{
            fill: '#febc00',
          }}
        />
        <path
          d="M32 28a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10Z"
          style={{
            fill: '#0455bf',
          }}
        />
        <path
          d="M34 26a9.981 9.981 0 0 1-7.989-15.989 9.987 9.987 0 1 0 13.978 13.978A9.933 9.933 0 0 1 34 26Z"
          style={{
            fill: '#004787',
          }}
        />
        <path
          d="m36.607 17.162-6.061-3.946a1 1 0 0 0-1.546.838V22a1 1 0 0 0 .525.881 1 1 0 0 0 1.026-.046l6.061-3.995a1 1 0 0 0-.005-1.673Z"
          style={{
            fill: '#febc00',
          }}
        />
        <path
          d="m36.607 17.162-4.056 2.668A1 1 0 0 1 31 19v-5.489l-.454-.295a1 1 0 0 0-1.546.838V22a1 1 0 0 0 .525.881 1 1 0 0 0 1.026-.046l6.061-3.995a1 1 0 0 0-.005-1.673Z"
          style={{
            fill: '#edaa03',
          }}
        />
        <path
          d="M20 30h-9v2h39v-2H20z"
          style={{
            fill: '#0455bf',
          }}
        />
        <path
          d="M18 31h-7v1h39v-1H18z"
          style={{
            fill: '#004787',
          }}
        />
        <path
          d="M17 33a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"
          style={{
            fill: '#febc00',
          }}
        />
        <path
          d="M17 30a1.973 1.973 0 0 1 .264-.973A1.92 1.92 0 0 0 17 29a2 2 0 1 0 1.736 2.973A2 2 0 0 1 17 30Z"
          style={{
            fill: '#edaa03',
          }}
        />
      </g>
    </svg>
  );
}
export default SvgComponent;
