import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.641}
      height={12.607}
      {...props}
    >
      <g data-name="Group 77" fill="#49cb86">
        <path
          data-name="Rectangle 40"
          d="M16.52 0l2.122 2.529L6.63 12.607l-2.122-2.529z"
        />
        <path
          data-name="Rectangle 41"
          d="M9.16 10.485L6.63 12.607.001 4.705 2.53 2.583z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
