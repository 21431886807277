import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: 'new 0 0 256 256',
      }}
      viewBox="0 0 256 256"
      {...props}
    >
      <style>
        {
          '.st2{fill:none;stroke:#6b1d1d;stroke-width:.5;stroke-miterlimit:10}.st3{fill:#3a312a}.st4{fill:#87796f}.st7{fill:#fce8cb}.st8{fill:#f16c7a}.st12{fill-rule:evenodd;clip-rule:evenodd;fill:#fce8cb}.st13{fill:#f5978a}.st14{fill:#d7e057}'
        }
      </style>
      <g id="Layer_1">
        <path
          d="M48.045 26.441S51.453 39.1 61.267 39.687c0 0-13.1 4.525-13.321 14.202 0 0-2.579-13.572-12.925-14.059-.001 0 12.05-1.825 13.024-13.389z"
          className="st14"
        />
        <path
          d="M47.945 55.389a1.5 1.5 0 0 1-1.472-1.217c-.024-.124-2.495-12.417-11.523-12.842a1.5 1.5 0 0 1-.155-2.982c.441-.069 10.894-1.818 11.754-12.033a1.5 1.5 0 0 1 2.942-.266c.032.116 3.259 11.626 11.864 12.141a1.501 1.501 0 0 1 .402 2.915c-.121.042-12.118 4.31-12.312 12.819a1.498 1.498 0 0 1-1.5 1.465zm-7.741-15.73c3.878 1.969 6.23 5.746 7.587 8.876 2.026-4.021 5.941-6.787 8.949-8.427-4.134-1.842-6.783-5.651-8.334-8.722-1.794 4.36-5.272 6.868-8.202 8.273z"
          className="st3"
        />
        <path
          d="M26.346 43.421s2.411 8.954 9.352 9.369c0 0-9.266 3.201-9.422 10.046 0 0-1.824-9.6-9.142-9.944 0 0 8.523-1.291 9.212-9.471z"
          className="st14"
        />
        <path
          d="M26.274 64.336a1.5 1.5 0 0 1-1.472-1.22c-.016-.082-1.704-8.442-7.739-8.726a1.502 1.502 0 0 1-.155-2.982c.296-.047 7.364-1.243 7.942-8.114a1.5 1.5 0 0 1 2.942-.266c.021.079 2.228 7.92 7.994 8.265a1.5 1.5 0 0 1 .4 2.915c-.081.028-8.282 2.952-8.413 8.662a1.501 1.501 0 0 1-1.499 1.466zm-4.518-11.527c2.107 1.333 3.523 3.394 4.447 5.28 1.376-2.232 3.515-3.872 5.365-4.972-2.287-1.27-3.884-3.35-4.941-5.224-1.174 2.286-3.015 3.875-4.871 4.916z"
          className="st3"
        />
        <path
          d="M26.346 16.764s2.411 8.954 9.352 9.369c0 0-9.266 3.201-9.422 10.045 0 0-1.824-9.6-9.142-9.944 0 .001 8.523-1.291 9.212-9.47z"
          className="st14"
        />
        <path
          d="M26.274 37.679a1.5 1.5 0 0 1-1.472-1.22c-.016-.082-1.704-8.441-7.739-8.726a1.5 1.5 0 0 1-.154-2.982c.296-.047 7.364-1.243 7.942-8.113a1.5 1.5 0 0 1 2.943-.265c.042.15 2.254 7.92 7.994 8.263a1.5 1.5 0 0 1 .4 2.915c-.081.028-8.282 2.952-8.413 8.662a1.5 1.5 0 0 1-1.501 1.466zm-4.518-11.528c2.107 1.333 3.523 3.394 4.447 5.279 1.376-2.233 3.515-3.872 5.365-4.972-2.287-1.269-3.884-3.35-4.941-5.224-1.174 2.287-3.015 3.876-4.871 4.917z"
          className="st3"
        />
        <path
          d="M27.5 168.049v71.59h201v-133.55l-98.8-83.8c-.98-.83-2.42-.83-3.4 0l-98.8 83.8v61.96z"
          style={{
            fill: '#8ac6dd',
          }}
        />
        <path
          d="M228.5 241.139h-201a1.5 1.5 0 0 1-1.5-1.5v-71.59a1.5 1.5 0 1 1 3 0v70.09h198V106.783l-98.27-83.351a1.13 1.13 0 0 0-1.461 0L29 106.783v46.445a1.5 1.5 0 1 1-3 0v-47.14c0-.44.194-.859.53-1.144l98.8-83.8a4.12 4.12 0 0 1 5.34-.001l98.801 83.8a1.5 1.5 0 0 1 .529 1.144v133.55c0 .83-.672 1.502-1.5 1.502z"
          className="st3"
        />
        <path
          d="m73.428 59.954-60.487 51.305a7 7 0 0 0 9.058 10.676L128 32.023l106.001 89.911a6.975 6.975 0 0 0 4.525 1.662 7 7 0 0 0 4.532-12.338L134.225 18.945a9.601 9.601 0 0 0-12.453.004l-38.34 32.52-10.004 8.485z"
          className="st8"
        />
        <path
          d="M17.483 125.097c-.235 0-.471-.009-.707-.029a8.442 8.442 0 0 1-5.788-2.973 8.442 8.442 0 0 1-1.99-6.193 8.444 8.444 0 0 1 2.973-5.787L72.458 58.81a1.5 1.5 0 1 1 1.94 2.288l-60.487 51.305a5.463 5.463 0 0 0-1.923 3.745 5.464 5.464 0 0 0 1.287 4.007 5.464 5.464 0 0 0 3.745 1.924 5.469 5.469 0 0 0 4.008-1.288L127.03 30.879a1.499 1.499 0 0 1 1.94 0l106.001 89.911a5.498 5.498 0 0 0 7.753-.636 5.464 5.464 0 0 0 1.287-4.007 5.466 5.466 0 0 0-1.924-3.745L133.254 20.089a8.107 8.107 0 0 0-10.512.004l-38.34 32.52a1.499 1.499 0 1 1-1.94-2.288l38.34-32.52a11.096 11.096 0 0 1 14.393-.004l108.835 92.314a8.451 8.451 0 0 1 2.972 5.787 8.444 8.444 0 0 1-1.989 6.194 8.492 8.492 0 0 1-6.485 3.001 8.507 8.507 0 0 1-5.495-2.018L128 33.99 22.969 123.078a8.437 8.437 0 0 1-5.486 2.019z"
          className="st3"
        />
        <path d="M80.832 106.64h93.103v106.564H80.832z" className="st4" />
        <path
          d="M173.935 214.704H80.831a1.5 1.5 0 0 1-1.5-1.5V106.64a1.5 1.5 0 0 1 1.5-1.5h93.104a1.5 1.5 0 0 1 1.5 1.5v106.564a1.5 1.5 0 0 1-1.5 1.5zm-91.604-3h90.104V108.14H82.331v103.564z"
          className="st3"
        />
        <path
          d="M183.11 217.116v22.536H72.89v-22.536c0-13.736 11.133-24.869 24.869-24.869h16.44v-10.734h.279c4.526 3.188 9.145 5.065 13.522 5.065 4.563 0 9.265-1.859 13.81-5.009v10.678h16.431c13.735 0 24.869 11.133 24.869 24.869z"
          className="st7"
        />
        <path
          d="M183.109 241.152H72.89a1.5 1.5 0 0 1-1.5-1.5v-22.536c0-14.54 11.829-26.369 26.369-26.369h14.94v-9.234a1.5 1.5 0 0 1 1.5-1.5h.278c.309 0 .611.096.864.273 4.515 3.18 8.773 4.792 12.659 4.792 4.058 0 8.417-1.596 12.955-4.742a1.5 1.5 0 0 1 2.355 1.232v9.179h14.931c14.54 0 26.369 11.829 26.369 26.369v22.536a1.501 1.501 0 0 1-1.501 1.5zm-108.719-3h107.22v-21.036c0-12.886-10.483-23.369-23.369-23.369H141.81a1.5 1.5 0 0 1-1.5-1.5v-7.933c-4.229 2.5-8.359 3.764-12.31 3.764-3.888 0-8.018-1.332-12.301-3.963v8.132a1.5 1.5 0 0 1-1.5 1.5h-16.44c-12.886 0-23.369 10.483-23.369 23.369v21.036z"
          className="st3"
        />
        <path
          d="M183.11 217.109v22.546H72.89v-22.546c0-13.736 11.133-24.869 24.869-24.869h4.935l-.102.075 17.09 22.667 8.819-6.645 8.82 6.645 17.091-22.667-.102-.075h3.931c13.735 0 24.869 11.133 24.869 24.869z"
          className="st8"
        />
        <path
          d="M183.11 241.155H72.89a1.5 1.5 0 0 1-1.5-1.5v-22.546c0-14.54 11.829-26.369 26.369-26.369h4.935a1.5 1.5 0 0 1 1.459 1.154l15.824 20.987 7.622-5.743a1.502 1.502 0 0 1 1.805 0l7.622 5.743 15.823-20.986a1.499 1.499 0 0 1 1.46-1.155h3.932c14.54 0 26.369 11.829 26.369 26.369v22.546a1.5 1.5 0 0 1-1.5 1.5zm-108.72-3h107.22v-21.046c0-12.886-10.483-23.369-23.369-23.369h-3.025l-16.697 22.144a1.5 1.5 0 0 1-2.1.295l-7.917-5.965-7.917 5.965a1.5 1.5 0 0 1-2.1-.295l-16.697-22.144h-4.029c-12.886 0-23.369 10.483-23.369 23.369v21.046z"
          className="st3"
        />
        <path
          d="M95.082 130.598c0 5.404-4.381 9.785-9.785 9.785-5.405 0-9.785-4.381-9.785-9.785 0-5.405 4.381-9.786 9.785-9.786s9.785 4.381 9.785 9.786z"
          className="st12"
        />
        <path
          d="M85.296 141.883c-6.223 0-11.286-5.062-11.286-11.286 0-6.223 5.062-11.285 11.286-11.285 6.223 0 11.285 5.062 11.285 11.285 0 6.224-5.062 11.286-11.285 11.286zm0-19.571c-4.569 0-8.286 3.717-8.286 8.285 0 4.569 3.717 8.286 8.286 8.286 4.568 0 8.285-3.717 8.285-8.286 0-4.568-3.716-8.285-8.285-8.285z"
          className="st3"
        />
        <path
          d="M180.492 130.598c0 5.404-4.381 9.785-9.786 9.785-5.404 0-9.785-4.381-9.785-9.785 0-5.405 4.381-9.786 9.785-9.786a9.786 9.786 0 0 1 9.786 9.786z"
          className="st12"
        />
        <path
          d="M170.706 141.883c-6.223 0-11.285-5.062-11.285-11.286 0-6.223 5.062-11.285 11.285-11.285 6.224 0 11.286 5.062 11.286 11.285 0 6.224-5.062 11.286-11.286 11.286zm0-19.571c-4.568 0-8.285 3.717-8.285 8.285 0 4.569 3.717 8.286 8.285 8.286 4.569 0 8.286-3.717 8.286-8.286 0-4.568-3.717-8.285-8.286-8.285z"
          className="st3"
        />
        <path
          d="M171.381 125.848c0 23.958-23.074 60.732-43.38 60.732-19.383 0-43.38-36.774-43.38-60.732s19.422-43.38 43.38-43.38c23.958 0 43.38 19.422 43.38 43.38z"
          className="st7"
        />
        <path
          d="M128.001 188.08c-9.374 0-20.661-8.235-30.192-22.029-9.059-13.111-14.687-28.516-14.687-40.203 0-24.747 20.133-44.88 44.879-44.88 24.747 0 44.88 20.133 44.88 44.88 0 11.819-5.493 27.221-14.336 40.195-9.544 14.004-20.677 22.037-30.544 22.037zm0-104.112c-23.092 0-41.879 18.787-41.879 41.88 0 11.11 5.424 25.862 14.155 38.497 8.971 12.983 19.335 20.734 27.724 20.734 8.728 0 19.221-7.748 28.065-20.727 8.521-12.503 13.814-27.257 13.814-38.505.001-23.092-18.786-41.879-41.879-41.879z"
          className="st3"
        />
        <path
          d="M116.92 125.358a4.234 4.234 0 1 1-8.469-.001 4.234 4.234 0 0 1 8.469.001zM147.551 125.358a4.234 4.234 0 1 1-8.468 0 4.234 4.234 0 0 1 8.468 0z"
          className="st3"
        />
        <path
          d="M111.517 137.946c0 3.011-3.266 5.453-7.294 5.453s-7.294-2.441-7.294-5.453c0-3.011 3.266-5.452 7.294-5.452 4.029-.001 7.294 2.441 7.294 5.452zM159.073 137.946c0 3.011-3.266 5.453-7.294 5.453s-7.294-2.441-7.294-5.453c0-3.011 3.266-5.452 7.294-5.452 4.028-.001 7.294 2.441 7.294 5.452z"
          className="st13"
        />
        <path
          d="m102.604 192.305 8.857-6.675 17.086 22.67-8.857 6.675z"
          className="st4"
        />
        <path
          d="M119.683 216.48c-.455 0-.904-.206-1.199-.597l-17.085-22.67a1.5 1.5 0 0 1 .295-2.101l8.856-6.674a1.503 1.503 0 0 1 2.1.294l17.086 22.67a1.499 1.499 0 0 1-.296 2.1l-8.856 6.674a1.48 1.48 0 0 1-.901.304zm-14.986-23.875 15.279 20.275 6.461-4.869-15.28-20.275-6.46 4.869z"
          className="st3"
        />
        <path
          d="m137.327 214.975-8.856-6.675 17.086-22.67 8.856 6.675z"
          className="st4"
        />
        <path
          d="M137.321 216.48c-.314 0-.632-.098-.901-.302l-8.857-6.674a1.5 1.5 0 0 1-.295-2.1l17.084-22.67a1.499 1.499 0 0 1 2.101-.295l8.857 6.674a1.5 1.5 0 0 1 .296 2.1l-17.085 22.67a1.5 1.5 0 0 1-1.2.597zm-6.755-8.469 6.461 4.869 15.279-20.275-6.461-4.869-15.279 20.275zM93.535 241.155a1.5 1.5 0 0 1-1.5-1.5v-17.812a1.5 1.5 0 1 1 3 0v17.812a1.5 1.5 0 0 1-1.5 1.5zM163.469 241.155a1.5 1.5 0 0 1-1.5-1.5v-17.812a1.5 1.5 0 1 1 3 0v17.812a1.5 1.5 0 0 1-1.5 1.5zM128 162.535c-6.226 0-10.714-2.798-13.341-8.316a1.5 1.5 0 0 1 2.709-1.289c2.116 4.444 5.594 6.605 10.632 6.605 6.447 0 9.741-4.243 10.582-6.487a1.5 1.5 0 0 1 2.809 1.053c-1.522 4.062-6.362 8.434-13.391 8.434z"
          className="st3"
        />
        <path
          d="M177.37 109.949c0 4.444-.578 8.753-1.671 12.854-11.111-5.357-21.68-11.933-31.472-19.367-2.872-2.177-5.7-4.445-8.401-6.847-3.315-2.909-6.45-6.043-9.277-9.44-6.151-7.407-10.668-15.917-14.489-24.778a49.621 49.621 0 0 1 15.321-2.403c14.299 0 27.199 6.007 36.314 15.637 8.481 8.951 13.675 21.038 13.675 34.344z"
          className="st4"
        />
        <path
          d="M175.699 124.303c-.223 0-.445-.05-.651-.149-10.931-5.27-21.605-11.838-31.728-19.523-3.235-2.453-6.014-4.717-8.491-6.921-3.543-3.109-6.72-6.342-9.433-9.601-5.495-6.617-10.17-14.606-14.714-25.144a1.5 1.5 0 0 1 .915-2.02 51.132 51.132 0 0 1 15.783-2.476c14.288 0 27.572 5.72 37.403 16.105 9.084 9.586 14.087 22.15 14.087 35.376 0 4.499-.579 8.953-1.722 13.24a1.501 1.501 0 0 1-1.449 1.113zm-61.59-60.99c4.215 9.511 8.556 16.811 13.594 22.876 2.612 3.138 5.679 6.257 9.112 9.271 2.426 2.158 5.145 4.373 8.318 6.779 9.449 7.174 19.383 13.358 29.553 18.398a48.57 48.57 0 0 0 1.184-10.689c0-12.455-4.711-24.286-13.265-33.313-9.26-9.782-21.77-15.168-35.225-15.168-4.525 0-8.982.621-13.271 1.846z"
          className="st3"
        />
        <path
          d="M163.694 75.604c-8.6 7.841-17.958 14.778-27.868 20.984-17.57 11.011-36.838 19.702-56.557 26.937a49.86 49.86 0 0 1-1.87-13.577c0-22.258 14.553-41.119 34.661-47.578a49.621 49.621 0 0 1 15.321-2.403c14.299 0 27.198 6.008 36.313 15.637z"
          className="st4"
        />
        <path
          d="M79.269 125.025a1.497 1.497 0 0 1-1.443-1.092 51.453 51.453 0 0 1-1.926-13.984c0-22.453 14.348-42.147 35.702-49.007a51.117 51.117 0 0 1 15.779-2.475c14.288 0 27.572 5.72 37.403 16.105a1.498 1.498 0 0 1-.078 2.139c-8.275 7.545-17.724 14.66-28.083 21.147-16.109 10.096-34.7 18.952-56.837 27.074a1.466 1.466 0 0 1-.517.093zm48.111-63.558c-5.087 0-10.086.784-14.858 2.33-20.112 6.46-33.623 25.006-33.623 46.151 0 3.93.47 7.824 1.398 11.598 21.259-7.893 39.176-16.48 54.731-26.229 9.73-6.093 18.638-12.743 26.503-19.782-9.14-9.083-21.208-14.068-34.151-14.068z"
          className="st3"
        />
        <ellipse
          cx={202.473}
          cy={53.444}
          className="st7"
          rx={37.098}
          ry={37.099}
          transform="rotate(-21.974 202.456 53.44)"
        />
        <path
          d="M202.474 92.043c-21.283 0-38.599-17.315-38.599-38.599 0-21.283 17.315-38.599 38.599-38.599s38.599 17.315 38.599 38.599c-.001 21.283-17.316 38.599-38.599 38.599zm0-74.198c-19.629 0-35.599 15.97-35.599 35.599 0 19.629 15.97 35.599 35.599 35.599s35.599-15.97 35.599-35.599c-.001-19.629-15.971-35.599-35.599-35.599z"
          className="st3"
        />
        <path
          d="m229.436 70.057-26.963 4.429-26.963-4.429V36.402l26.963 4.429 26.963-4.429z"
          className="st8"
        />
        <path
          d="M202.474 75.986c-.081 0-.163-.007-.243-.02l-26.963-4.429a1.5 1.5 0 0 1-1.257-1.48V36.402a1.502 1.502 0 0 1 1.743-1.48l26.72 4.388 26.72-4.388a1.497 1.497 0 0 1 1.743 1.48v33.655a1.5 1.5 0 0 1-1.257 1.48l-26.963 4.429c-.08.013-.162.02-.243.02zm-25.463-7.203 25.463 4.183 25.463-4.183V38.169l-25.22 4.142c-.16.026-.326.026-.486 0l-25.22-4.142v30.614zm52.425 1.274h.01-.01z"
          className="st3"
        />
        <path
          d="M202.474 75.986a1.5 1.5 0 0 1-1.5-1.5V40.831a1.5 1.5 0 1 1 3 0v33.655a1.5 1.5 0 0 1-1.5 1.5z"
          className="st3"
        />
        <path
          d="M227 117.967v120.17h-15V135.33a37.502 37.502 0 0 0-13.24-28.596l-18.03-15.297c-2.03-.36-4.01-.88-5.91-1.54a51.168 51.168 0 0 0-10.04-15.32c-5.4-5.71-11.84-10.01-18.92-12.71l-25.36-21.52 7.5-6.36 38.08 32.3c4.58 12.94 15.86 22.71 29.65 25.15l31.27 26.53z"
          style={{
            fill: '#6ca8bf',
          }}
        />
      </g>
    </svg>
  );
}
export default SvgComponent;
