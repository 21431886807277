import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
// Components
import { useTranslation } from 'react-i18next';
import TestimonialBox from '../Elements/TestimonialBox';

const renderReviews = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'reviews' });
  return [...Array(6).keys()].map((item) => (
    <LogoWrapper className="flexCenter">
      <TestimonialBox
        text={t(`items.${item}.text`)}
        author={t(`items.${item}.author`)}
      />
    </LogoWrapper>
  ));
};
export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>{renderReviews()}</Slider>
    </div>
  );
}
const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
