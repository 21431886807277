import React from 'react';
import Faq from 'react-faq-component';
import styled from 'styled-components';
import i18n from '../../core/i18n';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AccordionDetails from '@mui/material/AccordionDetails';

const styles = {
  titleTextColor: 'black',
  rowTitleColor: 'black',
  rowTitleTextSize: '20px',
};

const config = {
  animate: true,
  tabFocus: true,
};
const data = {
  title: i18n.t('faq.title'),
  rows: i18n.t('faq.items', { returnObjects: true }),
};

const AccordionCell = ({title, description}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
      >
        <HeaderTitle className="font20 extraBold">{title}</HeaderTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Description>{description}</Description>
      </AccordionDetails>
    </Accordion>
  )
}
export default function FaqSection() {
  const questions = i18n.t('faq.items', { returnObjects: true })
  return (
    <Wrapper id="faq">
      <div className="whiteBg" style={{ padding: '60px 0' }}>
        <div className="container font">
          {(questions as any).map((item) => (
            <AccordionCell title={item.title} description={item.content}/>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
    @media (max-width: 860px) {
        max-width: 95%;
        margin: 0 auto;
    }
`;

const HeaderTitle = styled.p`
    @media (max-width: 860px) {
       font-size: 20px;
    }
`;

const Description = styled.p`
    font-size: 18px;
`;
