import React from 'react';
import styled from 'styled-components';

export default function ProjectBox({ img }) {
  return (
    <Wrapper>
      <img className="radius8" src={img} alt="project" />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
