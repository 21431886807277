import React from 'react';
import styled from 'styled-components';
// Components
import ProjectBox from '../Elements/ProjectBox';
// Assets
import ProjectImg1 from '../../assets/img/projects/1.jpeg';
import ProjectImg2 from '../../assets/img/projects/2.jpeg';
import ProjectImg3 from '../../assets/img/projects/3.jpeg';
import i18n from '../../core/i18n';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useWindowDimensions from '../../core/hooks/useWindowDimension';

const RawImage = ({img}) => {
  return (
    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
    <ProjectBox img={img} />
  </div>);
}


const AccordionCell = ({title, img}) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h1 className="font30 extraBold">{title}</h1>
      </AccordionSummary>
      <AccordionDetails>
        <RawImage img={img} />
      </AccordionDetails>
    </Accordion>
  )
}

export default function Services() {
  const { width } = useWindowDimensions();
  return (
    <Wrapper id="services">
      <div className="whiteBg">
        {width > 860 ? <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{i18n.t('services.title')}</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <RawImage img={ProjectImg1}/>
            <RawImage img={ProjectImg2}/>
            <RawImage img={ProjectImg3}/>
          </div>
        </div> :
          <div>
          <AccordionCell title={i18n.t('services.group')} img={ProjectImg1} />
            <AccordionCell title={i18n.t('services.couple')} img={ProjectImg1} />
            <AccordionCell title={i18n.t('services.individual')} img={ProjectImg1} />
        </div>}
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`
    width: 100%;
    @media (max-width: 860px) {
        width: 100%;
        max-width: 95%;
        margin: 0 auto;
    }
`;
const HeaderInfo = styled.div`
    @media (max-width: 860px) {
        text-align: center;
    }
`;
