import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import FullButton from '../Buttons/FullButton';
import logoIcon from '../../assets/img/logo/logo1.png';
import i18n from '../../core/i18n';

export default function Header() {
  const { t } = useTranslation('translation', { keyPrefix: 'header' });
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">{t('title')}</h1>
          <HeaderP className="font15 semiBold">{t('description')}</HeaderP>
          <BtnWrapper>
            <FullButton
              title={i18n.t('core.more')}
              action={() => {
                scroller.scrollTo('study', {
                  spy: true,
                  smooth: true,
                  offset: -80,
                });
              }}
            />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <img
            src={logoIcon}
            alt="Logo"
            style={{ height: 'auto', width: '70%' }}
          />
        </ImageWrapper>
        <GreyDiv />
      </RightSide>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 80px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 300px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  background: rgba(138, 183, 240, 1);
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
