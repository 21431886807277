import React from 'react';
import styled from 'styled-components';
// Assets
import InteractiveIcon from '../../assets/svg/study/Interactive';
import DigitalResources from '../../assets/svg/study/DigitalResources';
import Organisation from '../../assets/svg/study/Organisation';
import Teachers from '../../assets/svg/study/Teachers';

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;
  switch (icon) {
    case 'interactive':
      getIcon = <InteractiveIcon />;
      break;
    case 'digital':
      getIcon = <DigitalResources />;
      break;
    case 'organisation':
      getIcon = <Organisation />;
      break;
    case 'teachers':
      getIcon = <Teachers />;
      break;
    default:
      getIcon = <InteractiveIcon />;
      break;
  }
  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font16">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
`;

const IconStyle = styled.div`
  height: 50px;
  width: 50px;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
    font-size: 30px;
  }
`;

const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  @media (max-width: 860px) {   
      max-width: 100%;
    font-size: 20px;
  }
`;
