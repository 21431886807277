import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 223.748 204"
      {...props}
    >
      <path
        fill="#5CB0FF"
        d="M119.748 188.178h96v-76h-96v76zm28-56c0-1.422.754-2.735 1.98-3.453a4 4 0 0 1 3.981-.032l32 18a3.999 3.999 0 0 1 0 6.97l-32 18a3.988 3.988 0 0 1-3.98-.032 4.001 4.001 0 0 1-1.98-3.453v-36z"
      />
      <path fill="#5CB0FF" d="M155.748 139.018v22.32l19.84-11.16z" />
      <path
        fill="#1C71DA"
        d="M99.748 168.178v-76h42.989c.015.787.07 1.558.07 2.351 0 2.211 1.789 4 4 4s4-1.789 4-4c0-.793-.06-1.563-.075-2.351h33.016c0-46.317-37.684-84-84-84h-8v.405c-42.575 4.045-76 39.978-76 83.595 0 46.316 37.684 84 84 84 2.21 0 4-1.79 4-4s-1.789-4-4-4zm-8-1.325c-19.929-6.438-35.059-36.394-35.059-72.324 0-.793.055-1.563.07-2.351h34.989v74.675zM57.165 84.178c2.585-31.206 16.608-56.163 34.583-61.969v61.969H57.165zm42.583 0V20.885c21.683 0 39.625 27.58 42.583 63.293H99.748zm75.57 0H150.35c-2.389-29.981-14.96-54.891-32-65.602 30.418 7.697 53.614 33.7 56.968 65.602zM81.146 18.576c-17.04 10.71-29.611 35.62-32 65.602H24.179c3.354-31.903 26.55-57.905 56.968-65.602zM23.748 92.178h25.016c-.014.788-.075 1.558-.075 2.351 0 28.533 9.217 53.658 23.124 68.26-28.11-11.162-48.065-38.574-48.065-70.611z"
      />
      <path
        fill="#1C71DA"
        d="M219.748 104.178h-104c-2.21 0-4 1.789-4 4v84c0 2.21 1.79 4 4 4h104c2.21 0 4-1.79 4-4v-84c0-2.211-1.789-4-4-4zm-4 84h-96v-76h96v76z"
      />
      <path
        fill="#FFF"
        d="M149.728 171.63a3.988 3.988 0 0 0 3.98.031l32-17.999a3.999 3.999 0 0 0 0-6.969l-32-18a4 4 0 0 0-5.96 3.485v36c0 1.422.754 2.734 1.98 3.453zm6.02-32.612 19.84 11.16-19.84 11.16v-22.32z"
      />
      <path
        fill="#FF5D5D"
        d="M184 28a3.998 3.998 0 0 1-2.828-6.828L195.314 7.03a3.998 3.998 0 0 1 5.657 0 3.998 3.998 0 0 1 0 5.657l-14.143 14.141A3.987 3.987 0 0 1 184 28z"
      />
      <path
        fill="#FF5D5D"
        d="M198.143 28a3.987 3.987 0 0 1-2.829-1.172l-14.142-14.142a3.998 3.998 0 0 1 0-5.656 3.998 3.998 0 0 1 5.656 0l14.143 14.142A3.998 3.998 0 0 1 198.143 28z"
      />
      <path
        fill="#00D40B"
        d="M26 204c-7.72 0-14-6.28-14-14s6.28-14 14-14 14 6.28 14 14-6.28 14-14 14zm0-20c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z"
      />
      <path
        fill="#FFC504"
        d="M15.315 30.628a4 4 0 0 1-2.829-1.172L1.172 18.142a4 4 0 0 1 0-5.657L12.486 1.172a3.998 3.998 0 0 1 5.656 0l11.314 11.313a3.998 3.998 0 0 1 0 5.657L18.143 29.456a4 4 0 0 1-2.829 1.172zM9.657 15.313l5.657 5.658 5.658-5.657-5.658-5.658-5.657 5.657z"
      />
    </svg>
  );
}
export default SvgComponent;
