import React from 'react';
import styled from 'styled-components';
// Components
import { useTranslation } from 'react-i18next';
import ServiceBox from '../Elements/ServiceBox';

const caseStudies = [
  {
    id: 0,
    icon: 'interactive',
  },
  {
    id: 1,
    icon: 'digital',
  },
  {
    id: 2,
    icon: 'organisation',
  },
  {
    id: 3,
    icon: 'teachers',
  },
];
const renderStudyCases = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'study' });
  return caseStudies.map((item) => (
    <ServiceBoxWrapper>
      <ServiceBox
        icon={item.icon}
        title={t(`items.${item.id}.title`)}
        subtitle={t(`items.${item.id}.description`)}
      />
    </ServiceBoxWrapper>
  ));
};

export default function Study() {
  const { t } = useTranslation('translation', { keyPrefix: 'study' });
  return (
    <Wrapper id="study">
      <Container className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t('title')}</h1>
            <p className="font20">{t('description')}</p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">{renderStudyCases()}</ServiceBoxRow>
        </div>
      </Container>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Container = styled.div`
  @media (max-width: 860px) {
      padding-top: 60px
  }
`;
