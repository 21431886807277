import React from 'react';
import styled from 'styled-components';
// Assets
import { useTranslation } from 'react-i18next';
import Telegram from '../../assets/svg/contacts/Telegram';
import Instagram from '../../assets/svg/contacts/Instagram';

export default function Contact() {
  const getIgIcon = <Instagram />;
  const getTgIcon = <Telegram />;

  const { t } = useTranslation('translation', { keyPrefix: 'contacts' });
  const openInstagram = () => {
    const instagramUrl = 'https://www.instagram.com/deutschler_ua/';
    window.open(instagramUrl, '_blank');
  };

  const openTelegram = () => {
    const telegram = 'https://www.t.me/Valeriia10_17/';
    window.open(telegram, '_blank');
  };
  return (
    <Wrapper id="contacts">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t('title')}</h1>
          </HeaderInfo>
          <Iconscontainer className="row">
            <IconStyle className="animate pointer" onClick={openInstagram}>
              {getIgIcon}
            </IconStyle>
            <IconStyle className="animate pointer" onClick={openTelegram}>
              {getTgIcon}
            </IconStyle>
          </Iconscontainer>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 30px 0 0px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const IconStyle = styled.div`
  height: 80px;
  width: 80px;
`;

const Iconscontainer = styled.div`
  margin-left: 0px;
  padding-bottom: 30px;
`;
