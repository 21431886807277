import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: 'new 0 0 256 256',
      }}
      viewBox="0 0 256 256"
      {...props}
    >
      <style>
        {
          '.st2{fill:none;stroke:#6b1d1d;stroke-width:.5;stroke-miterlimit:10}.st3{fill:#3a312a}.st4{fill:#87796f}.st7{fill:#fce8cb}.st8{fill:#f16c7a}.st9{fill:#8ac6dd}.st12{fill-rule:evenodd;clip-rule:evenodd;fill:#fce8cb}.st13{fill:#f5978a}.st14{fill:#d7e057}'
        }
      </style>
      <g id="Layer_1">
        <path
          d="M136.845 35.095s3.408 12.659 13.222 13.246c0 0-13.1 4.525-13.321 14.202 0 0-2.579-13.572-12.925-14.059-.001 0 12.05-1.825 13.024-13.389z"
          className="st14"
        />
        <path
          d="M136.744 64.043a1.502 1.502 0 0 1-1.473-1.217c-.023-.124-2.494-12.417-11.521-12.842a1.5 1.5 0 0 1-.155-2.982c.441-.069 10.895-1.817 11.754-12.033a1.501 1.501 0 0 1 2.943-.266c.031.116 3.259 11.626 11.863 12.141a1.501 1.501 0 0 1 .402 2.915c-.121.042-12.118 4.311-12.312 12.819a1.5 1.5 0 0 1-1.501 1.465zm-7.74-15.73c3.878 1.969 6.229 5.746 7.586 8.877 2.027-4.021 5.942-6.787 8.949-8.427-4.134-1.842-6.783-5.651-8.334-8.722-1.793 4.359-5.27 6.867-8.201 8.272z"
          className="st3"
        />
        <path
          d="M115.146 52.075s2.411 8.954 9.352 9.369c0 0-9.266 3.201-9.422 10.045 0 0-1.824-9.6-9.142-9.944 0 .001 8.523-1.29 9.212-9.47z"
          className="st14"
        />
        <path
          d="M115.075 72.99a1.5 1.5 0 0 1-1.473-1.22c-.016-.081-1.704-8.442-7.739-8.726a1.5 1.5 0 0 1-.155-2.982c.296-.047 7.363-1.243 7.942-8.114a1.501 1.501 0 0 1 1.361-1.368 1.51 1.51 0 0 1 1.582 1.101c.021.079 2.227 7.92 7.993 8.265a1.5 1.5 0 0 1 .399 2.915c-.08.028-8.282 2.952-8.411 8.662a1.501 1.501 0 0 1-1.499 1.467zm-4.519-11.528c2.107 1.333 3.523 3.394 4.447 5.28 1.376-2.232 3.516-3.872 5.365-4.972-2.287-1.27-3.885-3.35-4.941-5.224-1.174 2.287-3.016 3.875-4.871 4.916z"
          className="st3"
        />
        <path
          d="M115.146 25.418s2.411 8.954 9.352 9.37c0 0-9.266 3.201-9.422 10.045 0 0-1.824-9.6-9.142-9.944 0 0 8.523-1.292 9.212-9.471z"
          className="st14"
        />
        <path
          d="M115.075 46.333a1.5 1.5 0 0 1-1.473-1.22c-.016-.082-1.704-8.442-7.739-8.726a1.5 1.5 0 0 1-.155-2.982c.296-.047 7.363-1.243 7.942-8.114a1.501 1.501 0 0 1 1.361-1.368 1.51 1.51 0 0 1 1.582 1.102c.021.079 2.227 7.92 7.993 8.265a1.5 1.5 0 0 1 .399 2.915c-.08.028-8.282 2.952-8.411 8.662a1.501 1.501 0 0 1-1.499 1.466zm-4.519-11.528c2.107 1.333 3.523 3.394 4.447 5.28 1.376-2.232 3.516-3.872 5.365-4.972-2.287-1.27-3.885-3.35-4.941-5.224-1.174 2.286-3.016 3.875-4.871 4.916z"
          className="st3"
        />
        <path
          d="M185.723 239.623h47.029v-18.559c0-11.311-9.168-20.48-20.48-20.48h-13.531v-8.794c-3.743 2.595-7.615 4.126-11.373 4.126-3.604 0-7.408-1.546-11.135-4.171h-.229v8.84h-13.539c-11.311 0-20.48 9.169-20.48 20.48v18.559h43.738z"
          className="st7"
        />
        <path
          d="M232.752 241.123h-47.028a1.5 1.5 0 0 1 0-3h45.528v-17.059c0-10.465-8.515-18.979-18.979-18.979h-13.53a1.5 1.5 0 0 1-1.5-1.5v-6.073c-3.386 1.929-6.697 2.904-9.873 2.904-3.129 0-6.438-1.027-9.864-3.06v6.229a1.5 1.5 0 0 1-1.5 1.5h-13.539c-10.465 0-18.98 8.515-18.98 18.979v17.059h29.457a1.5 1.5 0 0 1 0 3h-30.957a1.5 1.5 0 0 1-1.5-1.5v-18.559c0-12.119 9.86-21.979 21.98-21.979h12.039v-7.34a1.5 1.5 0 0 1 1.5-1.5h.23c.309 0 .611.096.864.273 3.671 2.586 7.126 3.897 10.27 3.897 3.287 0 6.825-1.298 10.519-3.857a1.501 1.501 0 0 1 2.355 1.232v7.294h12.03c12.119 0 21.979 9.86 21.979 21.979v18.559a1.502 1.502 0 0 1-1.501 1.501z"
          className="st3"
        />
        <path
          d="M185.723 239.625h47.029v-18.566c0-11.311-9.169-20.48-20.48-20.48h-3.237l.084.062-14.074 18.666-7.263-5.472-7.263 5.472-14.074-18.666.084-.062h-4.064c-11.311 0-20.48 9.169-20.48 20.48v18.566h43.738z"
          className="st9"
        />
        <path
          d="M232.753 241.125h-47.029a1.5 1.5 0 0 1 0-3h45.529v-17.066c0-10.466-8.515-18.98-18.98-18.98h-2.358l-13.671 18.131a1.502 1.502 0 0 1-2.1.295l-6.361-4.792-6.36 4.792a1.503 1.503 0 0 1-2.1-.295l-13.67-18.131h-3.186c-10.465 0-18.98 8.515-18.98 18.98v17.066h29.457a1.5 1.5 0 0 1 0 3h-30.957a1.5 1.5 0 0 1-1.5-1.5v-18.566c0-12.12 9.86-21.98 21.98-21.98h4.064a1.502 1.502 0 0 1 1.446 1.097l12.839 17.029 6.065-4.569a1.502 1.502 0 0 1 1.805 0l6.066 4.569 12.839-17.027.019-.062a1.5 1.5 0 0 1 1.427-1.037h3.237c12.12 0 21.98 9.86 21.98 21.98v18.566a1.502 1.502 0 0 1-1.501 1.5z"
          className="st3"
        />
        <circle
          cx={152.203}
          cy={149.817}
          r={8.058}
          className="st12"
          transform="rotate(-80.781 152.206 149.814)"
        />
        <path
          d="M152.203 159.375c-5.27 0-9.559-4.288-9.559-9.559 0-5.271 4.288-9.559 9.559-9.559 5.271 0 9.559 4.288 9.559 9.559 0 5.271-4.288 9.559-9.559 9.559zm0-16.117a6.567 6.567 0 0 0-6.559 6.559 6.567 6.567 0 0 0 6.559 6.559 6.567 6.567 0 0 0 6.559-6.559 6.567 6.567 0 0 0-6.559-6.559z"
          className="st3"
        />
        <circle
          cx={222.538}
          cy={149.817}
          r={8.058}
          className="st12"
          transform="rotate(-22.5 222.528 149.811)"
        />
        <path
          d="M222.538 159.375c-5.271 0-9.559-4.288-9.559-9.559 0-5.271 4.288-9.559 9.559-9.559 5.27 0 9.559 4.288 9.559 9.559 0 5.271-4.288 9.559-9.559 9.559zm0-16.117a6.567 6.567 0 0 0-6.559 6.559c0 3.616 2.942 6.559 6.559 6.559s6.559-2.942 6.559-6.559a6.567 6.567 0 0 0-6.559-6.559z"
          className="st3"
        />
        <path
          d="M223.094 145.905c0 19.729-19.001 50.013-35.723 50.013-15.962 0-35.723-30.283-35.723-50.013 0-19.73 15.994-35.723 35.723-35.723s35.723 15.993 35.723 35.723z"
          className="st7"
        />
        <path
          d="M187.37 197.418c-7.923 0-17.064-6.653-25.081-18.255-7.489-10.839-12.142-23.582-12.142-33.258 0-20.525 16.698-37.224 37.223-37.224s37.224 16.698 37.224 37.224c0 9.783-4.541 22.523-11.852 33.25-7.91 11.607-17.158 18.263-25.372 18.263zm0-85.736c-18.87 0-34.223 15.353-34.223 34.224 0 18.434 19.119 48.513 34.223 48.513 7.101 0 15.658-6.338 22.894-16.952 6.988-10.255 11.33-22.348 11.33-31.561 0-18.872-15.353-34.224-34.224-34.224z"
          className="st3"
        />
        <circle cx={174.758} cy={145.502} r={3.487} className="st3" />
        <path
          d="M203.47 145.502a3.487 3.487 0 1 1-6.974 0 3.487 3.487 0 0 1 6.974 0z"
          className="st3"
        />
        <path
          d="M173.796 155.868c0 2.48-2.689 4.49-6.007 4.49-3.317 0-6.006-2.01-6.006-4.49 0-2.48 2.689-4.49 6.006-4.49 3.318 0 6.007 2.01 6.007 4.49zM212.958 155.868c0 2.48-2.689 4.49-6.006 4.49s-6.006-2.01-6.006-4.49c0-2.48 2.689-4.49 6.006-4.49s6.006 2.01 6.006 4.49z"
          className="st13"
        />
        <path
          d="M187.783 102.297c-20.421 0-36.975 15.827-36.975 35.351 0 2.066.374 3.606 1.068 5.53.327.907 5.622-1.989 6.073-2.307 2.255-1.587 4.097-3.738 5.474-6.117a20.873 20.873 0 0 0 1.426-3.002c1.218-3.211 4.53-12.147 7.893-11.446 4.509.94 9.619 1.471 15.041 1.471 5.421 0 10.532-.531 15.041-1.471 3.362-.701 6.674 8.235 7.893 11.446a20.873 20.873 0 0 0 1.426 3.002c2.274 3.934 6.117 7.68 10.79 8.362.012-.226.297-.303.509-.38 1.53-.556 1.315-3.862 1.315-5.088.001-19.524-16.553-35.351-36.974-35.351z"
          className="st4"
        />
        <path
          d="M152.163 144.833a1.743 1.743 0 0 1-1.697-1.145c-.695-1.926-1.158-3.689-1.158-6.039 0-20.32 17.26-36.852 38.476-36.852 21.215 0 38.475 16.531 38.475 36.851l.004.392c.026 1.804.075 5.137-2.2 6.065a1.52 1.52 0 0 1-1.346.496c-4.522-.66-8.961-4.061-11.872-9.096a22.405 22.405 0 0 1-1.529-3.221l-.077-.204c-3.872-10.216-5.753-10.372-6.107-10.306-4.784.998-9.947 1.503-15.347 1.503s-10.564-.505-15.347-1.503c-.335-.063-2.235.089-6.108 10.306l-.077.204a22.428 22.428 0 0 1-1.528 3.218c-1.557 2.689-3.6 4.969-5.91 6.596-.047.031-4.422 2.735-6.652 2.735zm71.637-.632zm-36.017-40.404c-19.562 0-35.476 15.186-35.476 33.852 0 1.54.229 2.726.637 4.016 1.292-.469 3.606-1.666 4.153-2.027 1.946-1.371 3.688-3.321 5.027-5.634a19.45 19.45 0 0 0 1.321-2.783l.077-.203c2.584-6.816 5.523-13.014 9.524-12.179 4.583.955 9.54 1.439 14.735 1.439 5.194 0 10.151-.484 14.735-1.439 4.001-.835 6.939 5.362 9.523 12.179l.077.203c.359.946.805 1.884 1.323 2.786 2.306 3.988 5.647 6.746 9.047 7.504.116-.06.245-.114.387-.165.185-.181.421-.915.387-3.262l-.005-.435c.003-18.667-15.911-33.852-35.472-33.852z"
          className="st3"
        />
        <path
          d="m166.457 200.63 7.293-5.496 14.07 18.668-7.292 5.497z"
          className="st4"
        />
        <path
          d="M180.52 220.806c-.455 0-.904-.206-1.199-.598l-14.07-18.669a1.497 1.497 0 0 1 .296-2.1l7.294-5.496a1.502 1.502 0 0 1 2.101.296l14.069 18.669a1.497 1.497 0 0 1-.296 2.1l-7.293 5.496c-.27.203-.587.302-.902.302zm-11.97-19.874 12.265 16.273 4.897-3.69-12.264-16.273-4.898 3.69z"
          className="st3"
        />
        <path
          d="m195.053 219.299-7.293-5.497 14.07-18.669 7.293 5.497z"
          className="st4"
        />
        <path
          d="M195.045 220.806c-.314 0-.632-.099-.901-.302l-7.293-5.496a1.496 1.496 0 0 1-.296-2.1l14.07-18.669a1.502 1.502 0 0 1 2.101-.296l7.293 5.496a1.496 1.496 0 0 1 .296 2.1l-14.07 18.669a1.5 1.5 0 0 1-1.2.598zm-5.191-7.291 4.898 3.69 12.265-16.273-4.897-3.69-12.266 16.273zM158.988 241.125a1.5 1.5 0 0 1-1.5-1.5v-14.668a1.5 1.5 0 0 1 3 0v14.668a1.5 1.5 0 0 1-1.5 1.5z"
          className="st3"
        />
        <path
          d="M157.151 109.234c6.064 7.636 13.318 15.777 22.079 18.267 14.111 4.012 39.087-.993 39.087-21.303 0-12.988-10.885-28.685-31.201-16.733-25.182 14.815-39.799-13.664-39.799-.676 0 4.105 1.654 8.2 3.825 11.925l6.009 8.52z"
          className="st4"
        />
        <path
          d="M189.105 130.273c-3.725 0-7.27-.471-10.285-1.328-9.004-2.56-16.3-10.538-22.843-18.777-.018-.022-.034-.045-.051-.068l-6.011-8.52a1.433 1.433 0 0 1-.07-.109c-2.711-4.652-4.028-8.8-4.028-12.68 0-1.702.197-3.767 1.738-4.639 1.82-1.033 3.959.249 7.196 2.191 6.431 3.856 17.191 10.31 31.604 1.831 10.906-6.417 18.477-4.743 22.905-2.209 7.292 4.171 10.557 13.168 10.557 20.235 0 7.166-2.995 13.215-8.662 17.496-6.12 4.62-14.458 6.577-22.05 6.577zm-30.753-21.938c6.225 7.835 13.111 15.399 21.287 17.724 8.452 2.404 21.369 1.539 29.706-4.758 4.957-3.744 7.471-8.825 7.471-15.102 0-6.201-2.797-14.057-9.046-17.631-5.366-3.067-12.244-2.31-19.894 2.191-15.947 9.381-28.125 2.08-34.668-1.844-1.541-.925-3.439-2.063-4.15-2.121-.083.188-.241.715-.241 1.996 0 3.32 1.174 6.957 3.588 11.114l5.947 8.431zM187.369 176.382c-5.236 0-9.013-2.355-11.225-6.999a1.501 1.501 0 0 1 2.709-1.291c1.719 3.609 4.425 5.29 8.517 5.29 5.166 0 7.797-3.382 8.466-5.17a1.5 1.5 0 0 1 2.811 1.051c-1.286 3.428-5.362 7.119-11.278 7.119zM216.577 241.125a1.5 1.5 0 0 1-1.5-1.5v-14.668a1.5 1.5 0 0 1 3 0v14.668a1.5 1.5 0 0 1-1.5 1.5z"
          className="st3"
        />
        <path
          d="M29.788 190.169v27.674h76.67v-87.756h-76.67v46.828z"
          className="st4"
        />
        <path
          d="M106.458 219.343H29.787a1.5 1.5 0 0 1-1.5-1.5v-27.674a1.5 1.5 0 0 1 3 0v26.174h73.671v-84.756H31.287v45.328a1.5 1.5 0 0 1-3 0v-46.828a1.5 1.5 0 0 1 1.5-1.5h76.671a1.5 1.5 0 0 1 1.5 1.5v87.756a1.5 1.5 0 0 1-1.5 1.5z"
          className="st3"
        />
        <path
          d="M114.013 221.064v18.559H23.247v-18.559c0-11.311 9.168-20.48 20.48-20.48h13.539v-8.84h.229c3.727 2.625 7.531 4.171 11.135 4.171 3.758 0 7.63-1.531 11.372-4.125v8.794h13.531c11.312.001 20.48 9.169 20.48 20.48z"
          className="st7"
        />
        <path
          d="M114.014 241.123H23.247a1.5 1.5 0 0 1-1.5-1.5v-18.559c0-12.119 9.86-21.979 21.979-21.979h12.039v-7.34a1.5 1.5 0 0 1 1.5-1.5h.229c.31 0 .611.096.863.273 3.673 2.586 7.129 3.897 10.272 3.897 3.287 0 6.825-1.298 10.518-3.857a1.501 1.501 0 0 1 2.355 1.232v7.294h12.03c12.12 0 21.98 9.86 21.98 21.979v18.559a1.498 1.498 0 0 1-1.498 1.501zm-89.267-3h87.767v-17.059c0-10.465-8.515-18.979-18.98-18.979h-13.53a1.5 1.5 0 0 1-1.5-1.5v-6.073c-3.385 1.929-6.695 2.904-9.872 2.904-3.129 0-6.438-1.027-9.865-3.06v6.229a1.5 1.5 0 0 1-1.5 1.5h-13.54c-10.466 0-18.979 8.515-18.979 18.979v17.059z"
          className="st3"
        />
        <path
          d="M114.014 221.059v18.566H23.247v-18.566c0-11.311 9.168-20.48 20.48-20.48h4.064l-.084.061 14.074 18.666 7.263-5.472 7.263 5.472 14.074-18.666-.084-.061h3.237c11.311 0 20.48 9.168 20.48 20.48z"
          className="st8"
        />
        <path
          d="M114.014 241.125H23.247a1.5 1.5 0 0 1-1.5-1.5v-18.566c0-12.12 9.86-21.98 21.98-21.98h4.063a1.502 1.502 0 0 1 1.446 1.097l12.84 17.029 6.065-4.569a1.502 1.502 0 0 1 1.805 0l6.065 4.569 12.84-17.029a1.5 1.5 0 0 1 1.446-1.097h3.237c12.119 0 21.979 9.86 21.979 21.98v18.566a1.5 1.5 0 0 1-1.499 1.5zm-89.267-3h87.767v-17.066c0-10.466-8.515-18.98-18.979-18.98h-2.359L77.505 220.21a1.502 1.502 0 0 1-2.1.295l-6.36-4.792-6.36 4.792a1.502 1.502 0 0 1-2.1-.295l-13.671-18.131h-3.186c-10.466 0-18.98 8.515-18.98 18.98v17.066z"
          className="st3"
        />
        <circle
          cx={33.464}
          cy={149.817}
          r={8.058}
          className="st12"
          transform="rotate(-76.714 33.47 149.826)"
        />
        <path
          d="M33.464 159.375c-5.271 0-9.559-4.288-9.559-9.559 0-5.271 4.288-9.559 9.559-9.559s9.559 4.288 9.559 9.559c-.001 5.271-4.289 9.559-9.559 9.559zm0-16.117a6.567 6.567 0 0 0-6.559 6.559c0 3.616 2.942 6.559 6.559 6.559s6.559-2.942 6.559-6.559a6.567 6.567 0 0 0-6.559-6.559z"
          className="st3"
        />
        <path
          d="M111.858 149.817a8.058 8.058 0 1 1-16.116 0 8.058 8.058 0 0 1 8.058-8.058 8.057 8.057 0 0 1 8.058 8.058z"
          className="st12"
        />
        <path
          d="M103.799 159.375c-5.271 0-9.558-4.288-9.558-9.559 0-5.271 4.287-9.559 9.558-9.559s9.559 4.288 9.559 9.559c-.001 5.271-4.289 9.559-9.559 9.559zm0-16.117a6.566 6.566 0 0 0-6.558 6.559 6.566 6.566 0 0 0 6.558 6.559 6.567 6.567 0 0 0 6.559-6.559 6.567 6.567 0 0 0-6.559-6.559z"
          className="st3"
        />
        <path
          d="M104.355 145.905c0 19.729-19.001 50.013-35.724 50.013-15.962 0-35.723-30.283-35.723-50.013 0-19.73 15.994-35.723 35.723-35.723 19.73 0 35.724 15.993 35.724 35.723z"
          className="st7"
        />
        <path
          d="M68.632 197.418c-7.923 0-17.064-6.653-25.082-18.255-7.489-10.839-12.142-23.582-12.142-33.258 0-20.525 16.698-37.224 37.224-37.224s37.224 16.698 37.224 37.224c0 9.783-4.541 22.523-11.852 33.25-7.91 11.607-17.158 18.263-25.372 18.263zm0-85.736c-18.871 0-34.224 15.353-34.224 34.224 0 18.434 19.119 48.513 34.224 48.513 7.1 0 15.658-6.338 22.893-16.952 6.989-10.255 11.331-22.349 11.331-31.561-.001-18.872-15.353-34.224-34.224-34.224z"
          className="st3"
        />
        <path
          d="M59.506 145.502a3.487 3.487 0 1 1-6.974 0 3.487 3.487 0 0 1 6.974 0zM84.731 145.502a3.487 3.487 0 1 1-6.974 0 3.487 3.487 0 0 1 6.974 0z"
          className="st3"
        />
        <path
          d="M55.057 155.868c0 2.48-2.689 4.49-6.007 4.49-3.317 0-6.006-2.01-6.006-4.49 0-2.48 2.689-4.49 6.006-4.49 3.318 0 6.007 2.01 6.007 4.49zM94.219 155.868c0 2.48-2.689 4.49-6.006 4.49s-6.006-2.01-6.006-4.49c0-2.48 2.689-4.49 6.006-4.49s6.006 2.01 6.006 4.49z"
          className="st13"
        />
        <path
          d="m47.72 200.634 7.292-5.497 14.07 18.669-7.292 5.497z"
          className="st4"
        />
        <path
          d="M61.781 220.806c-.455 0-.904-.206-1.199-.598l-14.069-18.669a1.497 1.497 0 0 1 .296-2.1l7.293-5.496a1.5 1.5 0 0 1 2.1.296l14.069 18.669a1.497 1.497 0 0 1-.296 2.1l-7.293 5.496a1.492 1.492 0 0 1-.901.302zm-11.969-19.874 12.264 16.273 4.898-3.69-12.264-16.273-4.898 3.69z"
          className="st3"
        />
        <path
          d="m76.313 219.302-7.293-5.496 14.07-18.67 7.293 5.497z"
          className="st4"
        />
        <path
          d="M76.307 220.806c-.314 0-.632-.099-.901-.302l-7.293-5.496a1.496 1.496 0 0 1-.296-2.1l14.069-18.669a1.502 1.502 0 0 1 2.101-.296l7.293 5.496a1.496 1.496 0 0 1 .296 2.1l-14.069 18.669a1.5 1.5 0 0 1-1.2.598zm-5.192-7.291 4.898 3.69 12.264-16.273-4.897-3.69-12.265 16.273zM40.249 241.125a1.5 1.5 0 0 1-1.5-1.5v-14.668a1.5 1.5 0 0 1 3 0v14.668a1.5 1.5 0 0 1-1.5 1.5zM97.839 241.125a1.5 1.5 0 0 1-1.5-1.5v-14.668a1.5 1.5 0 0 1 3 0v14.668a1.5 1.5 0 0 1-1.5 1.5zM68.631 176.382c-5.237 0-9.015-2.355-11.226-7a1.5 1.5 0 0 1 2.709-1.289c1.718 3.608 4.424 5.289 8.517 5.289 5.165 0 7.796-3.382 8.465-5.17a1.5 1.5 0 1 1 2.811 1.051c-1.285 3.428-5.36 7.119-11.276 7.119z"
          className="st3"
        />
        <path
          d="M109.287 132.812c0 3.66-.476 7.208-1.376 10.585-9.15-4.411-17.853-9.827-25.917-15.949-2.365-1.793-4.694-3.66-6.918-5.639-2.73-2.395-5.311-4.977-7.64-7.774-5.066-6.1-8.785-13.107-11.932-20.405a40.86 40.86 0 0 1 12.616-1.979c11.776 0 22.398 4.947 29.904 12.877 6.986 7.373 11.263 17.326 11.263 28.284z"
          className="st4"
        />
        <path
          d="M107.911 144.898c-.223 0-.445-.05-.651-.149-9.014-4.346-17.82-9.765-26.173-16.105-2.664-2.02-4.957-3.888-7.009-5.713-2.925-2.566-5.55-5.238-7.795-7.934-4.543-5.47-8.405-12.07-12.156-20.771a1.501 1.501 0 0 1 .915-2.021 42.388 42.388 0 0 1 13.078-2.051c11.841 0 22.848 4.74 30.994 13.346 7.527 7.944 11.673 18.354 11.673 29.313a42.55 42.55 0 0 1-1.427 10.973 1.501 1.501 0 0 1-1.449 1.112zM57.558 94.58c3.422 7.672 6.948 13.581 11.032 18.498 2.144 2.575 4.659 5.134 7.475 7.604 2 1.779 4.233 3.599 6.836 5.571 7.677 5.829 15.74 10.86 23.992 14.974.594-2.745.895-5.567.895-8.416 0-10.188-3.854-19.866-10.852-27.25-7.574-8.002-17.808-12.409-28.815-12.409-3.6 0-7.145.48-10.563 1.428z"
          className="st3"
        />
        <path
          d="M98.025 104.529c-7.082 6.457-14.789 12.17-22.949 17.281-14.469 9.068-30.336 16.224-46.575 22.183a41.086 41.086 0 0 1-1.54-11.181c0-18.33 11.984-33.862 28.543-39.181a40.86 40.86 0 0 1 12.616-1.979c11.776 0 22.399 4.947 29.905 12.877z"
          className="st4"
        />
        <path
          d="M28.501 145.492a1.5 1.5 0 0 1-1.444-1.092 42.586 42.586 0 0 1-1.597-11.589c0-18.605 11.889-34.924 29.584-40.609 4.2-1.36 8.601-2.05 13.075-2.05 11.841 0 22.848 4.74 30.994 13.346a1.502 1.502 0 0 1-.079 2.14c-6.83 6.227-14.623 12.095-23.164 17.443-13.279 8.323-28.606 15.624-46.854 22.319-.165.062-.34.092-.515.092zm39.619-52.34c-4.161 0-8.25.641-12.154 1.906-16.453 5.285-27.505 20.457-27.505 37.753 0 3.116.361 6.204 1.074 9.199 17.369-6.465 32.019-13.496 44.743-21.472 7.91-4.953 15.163-10.356 21.579-16.075-7.453-7.306-17.243-11.311-27.737-11.311z"
          className="st3"
        />
        <path
          d="M17 47.068c0-16.951 15.518-30.693 34.659-30.693 19.142 0 34.659 13.742 34.659 30.693 0 7.767-3.264 14.854-8.635 20.26l5.459 13.757c.525 1.322-.731 2.655-2.082 2.211l-20.179-6.642a38.867 38.867 0 0 1-9.222 1.107c-8.83 0-16.883-2.928-23.003-7.743-.05-.039-.101-.076-.151-.116a34.478 34.478 0 0 1-.993-.826 32.613 32.613 0 0 1-2.882-2.774l.022-.006C19.869 61.034 17 54.349 17 47.068z"
          className="st9"
        />
        <path
          d="M81.598 84.884c-.334 0-.674-.054-1.007-.163l-19.774-6.51a40.449 40.449 0 0 1-9.157 1.05c-8.821 0-17.32-2.864-23.931-8.063l-.148-.115a37.312 37.312 0 0 1-1.045-.869 34.208 34.208 0 0 1-3.012-2.9 1.504 1.504 0 0 1-.155-.202C18.292 61.438 15.5 54.33 15.5 47.068c0-17.752 16.221-32.193 36.159-32.193s36.159 14.442 36.159 32.193c0 7.546-2.97 14.826-8.384 20.605l5.103 12.858a3.15 3.15 0 0 1-.641 3.351 3.145 3.145 0 0 1-2.298 1.002zm-20.716-9.73c.158 0 .317.025.469.075l20.179 6.643c.036.012.106.036.184-.046.077-.082.049-.153.035-.187L76.29 67.882a1.5 1.5 0 0 1 .33-1.61c5.287-5.322 8.199-12.142 8.199-19.203 0-16.097-14.875-29.193-33.159-29.193S18.5 30.971 18.5 47.068c0 6.692 2.512 12.992 7.263 18.22.053.058.101.12.143.184a31.481 31.481 0 0 0 2.582 2.464c.315.271.63.536.953.794l.142.109c6.086 4.787 13.926 7.422 22.077 7.422a37.47 37.47 0 0 0 8.867-1.064c.117-.029.236-.043.355-.043z"
          className="st3"
        />
        <path
          d="M239 47.068c0-16.951-15.518-30.693-34.659-30.693-19.142 0-34.659 13.742-34.659 30.693 0 7.767 3.264 14.854 8.635 20.26l-5.459 13.757c-.525 1.322.732 2.655 2.082 2.211l20.179-6.642a38.867 38.867 0 0 0 9.222 1.107c8.829 0 16.883-2.928 23.003-7.743.05-.039.101-.076.151-.116.338-.269.667-.546.993-.826a32.613 32.613 0 0 0 2.882-2.774l-.022-.006C236.131 61.034 239 54.349 239 47.068z"
          className="st8"
        />
        <path
          d="M174.402 84.884a3.15 3.15 0 0 1-2.299-1.002 3.149 3.149 0 0 1-.641-3.351l5.102-12.858c-5.414-5.779-8.384-13.059-8.384-20.605 0-17.752 16.221-32.193 36.159-32.193S240.5 29.317 240.5 47.068c0 7.263-2.794 14.373-7.872 20.048a1.502 1.502 0 0 1-.151.198 34.003 34.003 0 0 1-3.015 2.902c-.337.29-.681.579-1.033.86l-.101.078c-6.667 5.243-15.166 8.107-23.987 8.107-3.083 0-6.161-.353-9.157-1.05l-19.775 6.51a3.21 3.21 0 0 1-1.007.163zm29.939-67.009c-18.284 0-33.159 13.096-33.159 29.193 0 7.062 2.912 13.881 8.199 19.203a1.5 1.5 0 0 1 .33 1.61l-5.459 13.757c-.014.034-.042.104.035.187.078.082.148.058.183.046l20.18-6.643c.265-.087.551-.1.824-.032a37.47 37.47 0 0 0 8.867 1.064c8.151 0 15.991-2.636 22.075-7.421a30.957 30.957 0 0 0 1.094-.901 31.652 31.652 0 0 0 2.588-2.47c.041-.063.088-.124.14-.181 4.751-5.228 7.263-11.528 7.263-18.22-.001-16.096-14.876-29.192-33.16-29.192z"
          className="st3"
        />
        <path
          d="M209.948 55.885h-10.356l-1.439 4.873h-9.316l11.096-29.523h9.951l11.093 29.523h-9.553l-1.476-4.873zm-1.892-6.384-3.258-10.613-3.225 10.613h6.483z"
          className="st4"
        />
        <path
          d="M220.979 62.258h-9.554a1.501 1.501 0 0 1-1.436-1.065l-1.154-3.808h-8.122l-1.121 3.798a1.498 1.498 0 0 1-1.438 1.075h-9.316a1.499 1.499 0 0 1-1.404-2.028l11.097-29.523a1.5 1.5 0 0 1 1.404-.972h9.951c.625 0 1.185.387 1.404.972l11.094 29.523a1.502 1.502 0 0 1-1.405 2.028zm-8.441-3h6.274l-9.967-26.523h-7.873l-9.97 26.523h6.029l1.121-3.798a1.498 1.498 0 0 1 1.438-1.075h10.356c.661 0 1.244.433 1.436 1.065l1.156 3.808zM208.056 51h-6.482a1.5 1.5 0 0 1-1.435-1.936l3.225-10.613a1.5 1.5 0 0 1 1.434-1.064h.002a1.5 1.5 0 0 1 1.434 1.06l3.258 10.613c.14.455.056.949-.228 1.332a1.505 1.505 0 0 1-1.208.608zm-4.459-3h2.43l-1.221-3.978L203.597 48z"
          className="st3"
        />
        <path
          d="M62.662 56.448c1.141.792 1.886 1.289 2.236 1.49.523.296 1.228.638 2.115 1.027l-2.537 5.115a29.615 29.615 0 0 1-3.786-2.205c-1.249-.853-2.122-1.494-2.618-1.924-2.014.873-4.538 1.309-7.572 1.309-4.485 0-8.023-1.168-10.614-3.504-3.06-2.766-4.591-6.653-4.591-11.66 0-4.86 1.34-8.636 4.018-11.328 2.679-2.692 6.42-4.038 11.228-4.038 4.9 0 8.682 1.316 11.348 3.947 2.665 2.632 3.998 6.397 3.998 11.298-.002 4.365-1.077 7.855-3.225 10.473zm-6.988-4.672c.725-1.302 1.087-3.249 1.087-5.84 0-2.98-.554-5.108-1.661-6.384-1.108-1.275-2.636-1.913-4.582-1.913-1.812 0-3.283.652-4.41 1.953-1.128 1.302-1.692 3.336-1.692 6.102 0 3.222.551 5.484 1.652 6.786 1.101 1.302 2.611 1.954 4.531 1.954.617 0 1.201-.061 1.752-.181-.765-.738-1.973-1.436-3.625-2.095l1.43-3.282c.806.148 1.433.329 1.883.544.45.215 1.326.779 2.628 1.691.308.215.644.437 1.007.665z"
          className="st4"
        />
        <path
          d="M64.474 65.581c-.22 0-.442-.048-.652-.15a31.319 31.319 0 0 1-3.979-2.317 49.33 49.33 0 0 1-2.032-1.448c-2.038.727-4.492 1.095-7.312 1.095-4.846 0-8.755-1.309-11.618-3.89-3.376-3.05-5.087-7.348-5.087-12.774 0-5.248 1.499-9.415 4.454-12.386 2.959-2.972 7.094-4.479 12.291-4.479 5.285 0 9.458 1.474 12.401 4.38 2.949 2.912 4.444 7.072 4.444 12.365 0 4.015-.875 7.405-2.606 10.105.484.325.735.482.866.557.47.265 1.136.588 1.97.954a1.502 1.502 0 0 1 .742 2.04l-2.538 5.115a1.5 1.5 0 0 1-1.344.833zm-6.404-7.129c.354 0 .704.125.981.366.448.388 1.283 1 2.482 1.819.752.514 1.514.985 2.276 1.409l1.174-2.367a16.895 16.895 0 0 1-.824-.434c-.261-.15-.775-.468-2.354-1.564a1.5 1.5 0 0 1-.305-2.183c1.913-2.332 2.883-5.535 2.883-9.521 0-4.461-1.195-7.903-3.552-10.23-2.362-2.332-5.825-3.515-10.294-3.515-4.432 0-7.756 1.176-10.164 3.596-2.376 2.389-3.581 5.844-3.581 10.27 0 4.606 1.34 8.056 4.097 10.547 2.294 2.067 5.527 3.117 9.608 3.117 2.813 0 5.16-.399 6.975-1.185.193-.084.396-.125.598-.125zm-7.471-2.517c-2.372 0-4.282-.837-5.676-2.485-1.351-1.596-2.007-4.133-2.007-7.755 0-3.167.674-5.485 2.059-7.084 1.42-1.64 3.285-2.472 5.544-2.472 2.391 0 4.313.817 5.714 2.429 1.366 1.575 2.029 3.985 2.029 7.368 0 2.876-.418 5.025-1.277 6.569a1.5 1.5 0 0 1-2.109.541c-.384-.241-.74-.476-1.067-.705-1.611-1.129-2.206-1.467-2.417-1.568a3.177 3.177 0 0 0-.364-.142l-.329.757c1.143.557 2.031 1.146 2.694 1.786a1.501 1.501 0 0 1-.72 2.545 9.734 9.734 0 0 1-2.074.216zm-.08-16.796c-1.368 0-2.439.47-3.276 1.436-.88 1.016-1.326 2.739-1.326 5.12 0 3.541.706 5.118 1.298 5.818.035.042.071.083.107.123l.03-.075 1.429-3.282c.278-.64.96-1.005 1.646-.877.944.173 1.683.391 2.258.664.302.145.855.446 2.25 1.404.218-.952.328-2.133.328-3.534 0-2.557-.447-4.425-1.295-5.402-.817-.938-1.945-1.395-3.449-1.395z"
          className="st3"
        />
        <path
          d="M231.25 221.055v17.07h-13.17v-13.17c0-.83-.67-1.5-1.5-1.5-.11 0-.23.01-.33.04v-2.44c0-6.66-3.45-12.53-8.65-15.91l2.31-3.07h2.36c10.47 0 18.98 8.52 18.98 18.98z"
          style={{
            fill: '#6ca8bf',
          }}
        />
        <path
          d="M112.51 221.055v17.07H99.34v-13.17c0-.83-.67-1.5-1.5-1.5-.11 0-.23.01-.33.04v-2.44c0-6.66-3.45-12.53-8.65-15.91l2.31-3.07h2.36c10.47 0 18.98 8.52 18.98 18.98z"
          style={{
            fill: '#d34e5c',
          }}
        />
      </g>
    </svg>
  );
}
export default SvgComponent;
